import { ADB_SYNC_MAX_PACKET_SIZE, Adb, AdbDaemonDevice } from "@yume-chan/adb";
import {
    Consumable,
    DistributionStream,
    ReadableStream,
} from "@yume-chan/stream-extra";
import { action } from "mobx";
import { fetchServer } from "../../../../components/scrcpy/fetch-server";
import { STATE } from "../../../../components/scrcpy/state";
import { GLOBAL_STATE } from "../../../../state";
import { ProgressStream } from "../../../../utils";
import { ARGS_STRING } from "../../common/Constants";

const TEMP_PATH = "/data/local/tmp/";
// const FILE_DIR = path.join(__dirname, "vendor/Genymobile/scrcpy");
const FILE_NAME = "ws_scrcpy-server.jar";
export const WS_SERVER_PATH = "/data/local/tmp/ws_scrcpy-server.jar";
const RUN_COMMAND = `CLASSPATH=${TEMP_PATH}${FILE_NAME} nohup app_process ${ARGS_STRING}`;

type WaitForPidParams = {
    tryCounter: number;
    processExited: boolean;
    lookPidFile: boolean;
};

export class ScrcpyServer {
    private static PID_FILE_PATH = "/data/local/tmp/ws_scrcpy.pid";
    private static async copyServer(device: AdbDaemonDevice) {
        const url = new URL(
            "./Genymobile/scrcpy/ws_scrcpy-server.jar",
            window.location.href,
        );

        let serverBuffer: Uint8Array;
        serverBuffer = await fetchServer(
            (e: [downloaded: number, total: number]) => {
                console.log("Server Downloaded", e);
            },
            url,
        );
        console.log("Server Buffer", serverBuffer);

        return this.pushServer(
            GLOBAL_STATE.adb!,
            new ReadableStream<Consumable<Uint8Array>>({
                start(controller) {
                    controller.enqueue(new Consumable(serverBuffer));
                    controller.close();
                },
            })
                // In fact `pushServer` will pipe the stream through a DistributionStream,
                // but without this pipeThrough, the progress will not be updated.
                .pipeThrough(new DistributionStream(ADB_SYNC_MAX_PACKET_SIZE))
                .pipeThrough(
                    new ProgressStream(
                        action((progress) => {
                            console.log(progress);
                        }),
                    ),
                ),
            WS_SERVER_PATH,
        );
        // const src = path.join(FILE_DIR, FILE_NAME);
        // const dst = TEMP_PATH + FILE_NAME; // don't use path.join(): will not work on win host
        // return device.push(src, dst);
    }

    static async pushServer(
        adb: Adb,
        file: ReadableStream<Consumable<Uint8Array>>,
        filename: string,
    ) {
        const sync = await adb.sync();
        try {
            await sync.write({
                filename,
                file,
            });
        } finally {
            await sync.dispose();
        }
    }

    // Important to notice that we first try to read PID from file.
    // Checking with `.getServerPid()` will return process id, but process may stop.
    // PID file only created after WebSocket server has been successfully started.
    private static async waitForServerPid(
        device: AdbDaemonDevice,
        params: WaitForPidParams,
    ): Promise<number[] | undefined> {
        const { tryCounter, processExited, lookPidFile } = params;
        if (processExited) {
            return;
        }
        const timeout = 500 + 100 * tryCounter;
        if (lookPidFile) {
            const fileName = ScrcpyServer.PID_FILE_PATH;
            const content = await STATE.runShellCommandAdbForResult(
                device,
                `test -f ${fileName} && cat ${fileName}`,
            );
            if (content.trim()) {
                const pid = parseInt(content, 10);
                if (pid && !isNaN(pid)) {
                    const realPid = await STATE.getServerPid(device);
                    if (realPid?.includes(pid)) {
                        return realPid;
                    } else {
                        params.lookPidFile = false;
                    }
                }
            }
        } else {
            const list = await STATE.getServerPid(device);
            if (Array.isArray(list) && list.length) {
                return list;
            }
        }
        if (++params.tryCounter > 5) {
            throw new Error("Failed to start server");
        }
        return new Promise<number[] | undefined>((resolve) => {
            setTimeout(() => {
                resolve(this.waitForServerPid(device, params));
            }, timeout);
        });
    }

    public static async run(
        device: AdbDaemonDevice,
    ): Promise<number[] | undefined> {
        let list: number[] | string | undefined = await STATE.getServerPid(
            device,
        );
        if (Array.isArray(list) && list.length) {
            return list;
        }
        await this.copyServer(device);

        const params: WaitForPidParams = {
            tryCounter: 0,
            processExited: false,
            lookPidFile: true,
        };
        const runPromise = STATE.runShellCommandAdbForResult(
            device,
            RUN_COMMAND,
        );
        runPromise
            .then((adbResult) => {
                if (GLOBAL_STATE.device) {
                    console.log("Server exited:", adbResult);
                }
            })
            .catch((e) => {
                console.log("Error:", e.message);
            })
            .finally(() => {
                params.processExited = true;
            });

        let list1 = await Promise.race([
            runPromise,
            this.waitForServerPid(device, params),
        ]);
        if (Array.isArray(list1) && list1.length) {
            return list1;
        }
        return;
    }
}
