import { StreamReceiver } from '../../client/StreamReceiver'
import { ParamsStreamScrcpy } from '../../../types/ParamsStreamScrcpy'
import { ACTION } from '../../../common/Action'
import Util from '../../Util'

export class StreamReceiverScrcpy extends StreamReceiver<ParamsStreamScrcpy> {
   public static parseParameters(params: URLSearchParams): ParamsStreamScrcpy {
      const typedParams = super.parseParameters(params)
      const { action } = typedParams
      if (action !== ACTION.STREAM_SCRCPY) {
         throw Error('Incorrect action')
      }
      return {
         ...typedParams,
         action,
         udid: Util.parseString(params, 'udid', true),
         ws: Util.parseString(params, 'ws', true),
         player: Util.parseString(params, 'player', true),
      }
   }
   protected buildDirectWebSocketUrl(): URL {
      return new URL((this.params as ParamsStreamScrcpy).ws)
   }
}
