import { ContextualMenuItemType, ICommandBarItemProps } from "@fluentui/react";
import { sendGAEvent } from "@next/third-parties/google";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { GLOBAL_STATE } from "../../state";
import { Icons } from "../../utils";
import { CommandBar } from "../command-bar";
import { CommandBarSpacerItem } from "../command-bar-spacer-item";
import { STATE } from "./state";

export interface ScrcpyCommandBarProps {
    wireless?: boolean;
}

export const ScrcpyCommandBar = observer((props: ScrcpyCommandBarProps) => {
    const result: ICommandBarItemProps[] = [];
    const { wireless } = props;

    if (!wireless) {
        result.push({
            key: "stop",
            iconProps: { iconName: Icons.Stop },
            text: "Stop",
            onClick: STATE.stop as VoidFunction,
        });
    }

    result.push({
        key: "fullscreen",
        disabled: !STATE.running,
        iconProps: { iconName: Icons.FullScreenMaximize },
        iconOnly: true,
        text: "Fullscreen",
        onClick: action(() => {
            sendGAEvent("event", "enter_fullscreen", {
                serial: GLOBAL_STATE.device?.serial,
                name: GLOBAL_STATE.device?.name,
            });
            STATE.fullScreenContainer!.focus();

            STATE.fullScreenContainer!.requestFullscreen();
            STATE.isFullScreen = true;
        }),
    });

    result.push({
        key: "aspectratio",
        disabled: !STATE.running,
        iconProps: { iconName: Icons.AspectRatio },
        text: STATE.aspectRatio === "fill" ? "Fill" : STATE.aspectRatio,
        // text: "Change aspect ratio",
        onClick: action(() => {
            if (STATE.aspectRatio === "16:9") {
                STATE.aspectRatio = "1:1";
            } else if (STATE.aspectRatio === "1:1") {
                STATE.aspectRatio = "fill";
            } else {
                STATE.aspectRatio = "16:9";
            }
            sendGAEvent("event", "change_aspect_ratio", {
                new_aspect_ratio: STATE.aspectRatio,
                serial: GLOBAL_STATE.device?.serial,
                name: GLOBAL_STATE.device?.name,
            });
        }),
    });

    if (!wireless && STATE.running) {
        result.push(
            {
                // HACK: make CommandBar overflow on far items
                // https://github.com/microsoft/fluentui/issues/11842
                key: "spacer",
                onRender: () => <CommandBarSpacerItem />,
            },
            {
                // HACK: add a separator in CommandBar overflow menu
                // https://github.com/microsoft/fluentui/issues/10035
                key: "separator",
                disabled: true,
                itemType: ContextualMenuItemType.Divider,
            },
        );
        result.push({
            key: "fps",
            text: `FPS: ${STATE.fps}`,
            disabled: true,
        });
    }

    return <CommandBar items={result} />;
});
