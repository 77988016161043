/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-plusplus */
import type {
    ScrcpyMediaStreamDataPacket,
    ScrcpyMediaStreamPacket,
} from "@yume-chan/scrcpy";
import {
    ScrcpyVideoCodecId,
    h264ParseConfiguration,
    h265ParseConfiguration,
} from "@yume-chan/scrcpy";
import type {
    ScrcpyVideoDecoder,
    ScrcpyVideoDecoderCapability,
} from "@yume-chan/scrcpy-decoder-tinyh264";
import { WritableStream } from "@yume-chan/stream-extra";

function toHex(value: number) {
    return value.toString(16).padStart(2, "0").toUpperCase();
}

function toUint32Le(data: Uint8Array, offset: number) {
    return (
        data[offset]! |
        (data[offset + 1]! << 8) |
        (data[offset + 2]! << 16) |
        (data[offset + 3]! << 24)
    );
}

export class VRDeviceCanvas {
    #canvas: HTMLCanvasElement;
    get canvas() {
        return this.#canvas;
    }

    #gl: WebGLRenderingContext | null = null;
    #texture: WebGLTexture | null = null;
    #program: WebGLProgram | null = null;

    constructor(canvas: HTMLCanvasElement, deviceModel?: string) {
        this.#canvas = canvas;
        this.#initializeWebGL();
        if (deviceModel === "Quest 3") {
            this.setShaderRotationAngle(-0.34); // Approx. 20 degrees in radians
        }
    }

    public setViewport(width: number, height: number) {
        this.#gl?.viewport(0, 0, width, height);
        this.#canvas.width = width;
        this.#canvas.height = height;
    }

    #initializeWebGL() {
        this.#gl = this.#canvas.getContext("webgl");
        if (!this.#gl) {
            console.error(
                "Unable to initialize WebGL. Your browser may not support it.",
            );
            return;
        }
        this.setViewport(this.#canvas.width, this.#canvas.height);

        // VERTEX SHADER - SIMPLE FLIPPED Y COORDINATE
        const vertexShaderSource = `
            attribute vec4 a_position;
            attribute vec2 a_texCoord;
            varying vec2 v_texCoord;

            void main() {
                gl_Position = a_position;
                // Flip the Y-coordinate
                v_texCoord = vec2(a_texCoord.x, 1.0 - a_texCoord.y);
            }`;

        // FRAGMENT SHADER - PINCUSHION DISTORTION WITH ROTATION AND TRANSLATION
        const fragmentShaderSource = `
            precision mediump float;
            varying vec2 v_texCoord;
            uniform sampler2D u_image;
            uniform float u_distortionStrength;
            uniform float u_rotationAngle; // Rotation angle in radians
            uniform vec2 u_translation;

            void main() {
                vec2 center = vec2(0.5, 0.5);
                vec2 coord = v_texCoord - center;

                // Rotate the coordinates
                float cosTheta = cos(u_rotationAngle);
                float sinTheta = sin(u_rotationAngle);
                vec2 rotatedCoord = vec2(
                    coord.x * cosTheta - coord.y * sinTheta,
                    coord.x * sinTheta + coord.y * cosTheta
                );

                rotatedCoord = rotatedCoord + u_translation;

                // Apply the pincushion distortion to the rotated coordinates
                float distance = length(rotatedCoord);
                float amount = 1.0 + distance * distance * u_distortionStrength;
                vec2 distortedCoord = center + rotatedCoord * amount;

                // Sample the texture with the distorted and rotated coordinates
                gl_FragColor = texture2D(u_image, distortedCoord);
            }`;

        const vertexShader = this.createShader(
            this.#gl,
            this.#gl.VERTEX_SHADER,
            vertexShaderSource,
        );
        const fragmentShader = this.createShader(
            this.#gl,
            this.#gl.FRAGMENT_SHADER,
            fragmentShaderSource,
        );

        if (!vertexShader || !fragmentShader) {
            return;
        }

        this.#program = this.createProgram(
            this.#gl,
            vertexShader,
            fragmentShader,
        );
        if (!this.#program) {
            return;
        }

        this.#gl.useProgram(this.#program);
        this.#prepareQuad();
        this.#texture = this.#gl.createTexture();

        this.setShaderDistortionStrength(-0.5);
        this.setShaderRotationAngle(0);
        this.setShaderTranslation(0, 0);
    }

    setShaderDistortionStrength(value: number) {
        if (!this.#gl || !this.#program) return;

        const distortionStrengthLocation = this.#gl.getUniformLocation(
            this.#program,
            "u_distortionStrength",
        );
        this.#gl.uniform1f(distortionStrengthLocation, value);
    }

    setShaderRotationAngle(value: number) {
        if (!this.#gl || !this.#program) return;

        const rotationAngleLocation = this.#gl.getUniformLocation(
            this.#program,
            "u_rotationAngle",
        );
        this.#gl.uniform1f(rotationAngleLocation, value);
    }

    setShaderTranslation(x: number, y: number) {
        if (!this.#gl || !this.#program) return;

        const translationLocation = this.#gl.getUniformLocation(
            this.#program,
            "u_translation",
        );
        this.#gl.uniform2f(translationLocation, x, y);
    }

    #prepareQuad() {
        // Assuming your createShader, createProgram methods are implemented as per your snippet

        const positionLocation = this.#gl!.getAttribLocation(
            this.#program!,
            "a_position",
        );
        const texCoordLocation = this.#gl!.getAttribLocation(
            this.#program!,
            "a_texCoord",
        );

        const positionBuffer = this.#gl!.createBuffer();
        this.#gl!.bindBuffer(this.#gl!.ARRAY_BUFFER, positionBuffer);
        this.setRectangle(this.#gl!, -1, -1, 2, 2); // Full screen quad

        const texCoordBuffer = this.#gl!.createBuffer();
        this.#gl!.bindBuffer(this.#gl!.ARRAY_BUFFER, texCoordBuffer);
        this.#gl!.bufferData(
            this.#gl!.ARRAY_BUFFER,
            new Float32Array([
                0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
            ]),
            this.#gl!.STATIC_DRAW,
        );

        // Enable the position attribute
        this.#gl!.enableVertexAttribArray(positionLocation);
        this.#gl!.bindBuffer(this.#gl!.ARRAY_BUFFER, positionBuffer);
        this.#gl!.vertexAttribPointer(
            positionLocation,
            2,
            this.#gl!.FLOAT,
            false,
            0,
            0,
        );

        // Enable the texCoord attribute
        this.#gl!.enableVertexAttribArray(texCoordLocation);
        this.#gl!.bindBuffer(this.#gl!.ARRAY_BUFFER, texCoordBuffer);
        this.#gl!.vertexAttribPointer(
            texCoordLocation,
            2,
            this.#gl!.FLOAT,
            false,
            0,
            0,
        );
    }

    getFloatFromLocalStorage(key: string, defaultValue: number): number {
        const value = localStorage.getItem(key);
        if (value === null) {
            return defaultValue;
        }

        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            return defaultValue;
        }

        return parsedValue;
    }

    public drawVideoFrame(frame: VideoFrame | HTMLVideoElement) {
        if (!this.#gl || !this.#texture) {
            console.error("WebGL is not initialized.");
            return;
        }

        this.#gl.bindTexture(this.#gl.TEXTURE_2D, this.#texture);
        this.#gl.texImage2D(
            this.#gl.TEXTURE_2D,
            0,
            this.#gl.RGBA,
            this.#gl.RGBA,
            this.#gl.UNSIGNED_BYTE,
            frame,
        );

        this.#gl.texParameteri(
            this.#gl.TEXTURE_2D,
            this.#gl.TEXTURE_WRAP_S,
            this.#gl.CLAMP_TO_EDGE,
        );
        this.#gl.texParameteri(
            this.#gl.TEXTURE_2D,
            this.#gl.TEXTURE_WRAP_T,
            this.#gl.CLAMP_TO_EDGE,
        );
        this.#gl.texParameteri(
            this.#gl.TEXTURE_2D,
            this.#gl.TEXTURE_MIN_FILTER,
            this.#gl.LINEAR,
        );

        this.#gl.drawArrays(this.#gl.TRIANGLES, 0, 6); // Draw the quad
    }

    createShader = (
        gl: WebGLRenderingContext,
        type: number,
        source: string,
    ): WebGLShader | null => {
        const shader = gl.createShader(type);
        if (!shader) {
            console.error("Unable to create shader");
            return null;
        }
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        const success = gl.getShaderParameter(
            shader,
            gl.COMPILE_STATUS,
        ) as boolean;
        if (success) {
            return shader;
        }
        console.error(gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    };

    createProgram = (
        gl: WebGLRenderingContext,
        vertexShader: WebGLShader,
        fragmentShader: WebGLShader,
    ): WebGLProgram | null => {
        const program = gl.createProgram();
        if (!program) {
            console.error("Unable to create shader program");
            return null;
        }
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        const success = gl.getProgramParameter(
            program,
            gl.LINK_STATUS,
        ) as boolean;
        if (success) {
            return program;
        }
        console.error(gl.getProgramInfoLog(program));
        gl.deleteProgram(program);
        return null;
    };

    setRectangle = (
        gl: WebGLRenderingContext,
        x: number,
        y: number,
        width: number,
        height: number,
    ): void => {
        const x1 = x;
        const x2 = x + width;
        const y1 = y;
        const y2 = y + height;
        const vertices = new Float32Array([
            x1,
            y1,
            x2,
            y1,
            x1,
            y2,
            x1,
            y2,
            x2,
            y1,
            x2,
            y2,
        ]);
        gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
    };

    private debugUpdateShaderLoop() {
        setTimeout(() => {
            if (this.#gl && this.#program) {
                console.log("DEBUG Update shader");

                this.setShaderDistortionStrength(
                    localStorage.getItem("distortionStrength")
                        ? parseFloat(
                              localStorage.getItem("distortionStrength")!,
                          )
                        : -0.5,
                );

                this.setShaderRotationAngle(
                    localStorage.getItem("rotationAngle")
                        ? parseFloat(localStorage.getItem("rotation")!)
                        : -0.34,
                );

                this.setShaderTranslation(
                    localStorage.getItem("translationX")
                        ? parseFloat(localStorage.getItem("translationX")!)
                        : 0,
                    localStorage.getItem("translationY")
                        ? parseFloat(localStorage.getItem("translationY")!)
                        : 0,
                );
            }
            this.debugUpdateShaderLoop();
        }, 1000);
    }
}

export class WebCodecsDecoder implements ScrcpyVideoDecoder {
    static isSupported() {
        return typeof globalThis.VideoDecoder !== "undefined";
    }

    static readonly capabilities: Record<string, ScrcpyVideoDecoderCapability> =
        {
            h264: {},
            h265: {},
        };

    #codec: ScrcpyVideoCodecId;
    get codec() {
        return this.#codec;
    }

    #writable: WritableStream<ScrcpyMediaStreamPacket>;
    get writable() {
        return this.#writable;
    }

    #renderer: HTMLCanvasElement;
    get renderer() {
        return this.#renderer;
    }

    #frameRendered = 0;
    get frameRendered() {
        return this.#frameRendered;
    }

    #frameSkipped = 0;
    get frameSkipped() {
        return this.#frameSkipped;
    }

    #decoder: VideoDecoder;
    #config: Uint8Array | undefined;

    #currentFrameRendered = false;
    #animationFrameId = 0;
    vrDeviceCanvas: VRDeviceCanvas;

    constructor(codec: ScrcpyVideoCodecId, deviceName?: string) {
        this.#codec = codec;

        this.#renderer = document.createElement("canvas");

        this.vrDeviceCanvas = new VRDeviceCanvas(this.#renderer, deviceName);

        this.#decoder = new VideoDecoder({
            output: (frame: VideoFrame) => {
                if (this.#currentFrameRendered) {
                    this.#frameSkipped += 1;
                } else {
                    this.#currentFrameRendered = true;
                    this.#frameRendered += 1;
                }

                // PERF: H.264 renderer may draw multiple frames in one vertical sync interval to minimize latency.
                // When multiple frames are drawn in one vertical sync interval,
                // only the last one is visible to users.
                // But this ensures users can always see the most up-to-date screen.
                // This is also the behavior of official Scrcpy client.
                // https://github.com/Genymobile/scrcpy/issues/3679
                // this.#drawVideoFrame(frame);
                this.vrDeviceCanvas.drawVideoFrame(frame);

                frame.close();
            },
            error(e) {
                void e;
            },
        });

        this.#writable = new WritableStream<ScrcpyMediaStreamPacket>({
            write: (packet) => {
                switch (packet.type) {
                    case "configuration":
                        this.#configure(packet.data);
                        break;
                    case "data":
                        this.#decode(packet);
                        break;
                }
            },
        });

        this.#onFramePresented();
    }

    #onFramePresented = () => {
        this.#currentFrameRendered = false;
        this.#animationFrameId = requestAnimationFrame(this.#onFramePresented);
    };

    #configure(data: Uint8Array) {
        switch (this.#codec) {
            case ScrcpyVideoCodecId.H264: {
                const {
                    profileIndex,
                    constraintSet,
                    levelIndex,
                    croppedWidth,
                    croppedHeight,
                } = h264ParseConfiguration(data);

                this.vrDeviceCanvas.setViewport(croppedWidth, croppedHeight);

                // https://www.rfc-editor.org/rfc/rfc6381#section-3.3
                // ISO Base Media File Format Name Space
                const codec = `avc1.${[profileIndex, constraintSet, levelIndex]
                    .map(toHex)
                    .join("")}`;
                this.#decoder.configure({
                    codec: codec,
                    optimizeForLatency: true,
                });
                break;
            }
            case ScrcpyVideoCodecId.H265: {
                const {
                    generalProfileSpace,
                    generalProfileIndex,
                    generalProfileCompatibilitySet,
                    generalTierFlag,
                    generalLevelIndex,
                    generalConstraintSet,
                    croppedWidth,
                    croppedHeight,
                } = h265ParseConfiguration(data);

                this.#renderer.width = croppedWidth;
                this.#renderer.height = croppedHeight;

                const codec = [
                    "hev1",
                    ["", "A", "B", "C"][generalProfileSpace]! +
                        generalProfileIndex.toString(),
                    toUint32Le(generalProfileCompatibilitySet, 0).toString(16),
                    (generalTierFlag ? "H" : "L") +
                        generalLevelIndex.toString(),
                    toUint32Le(generalConstraintSet, 0)
                        .toString(16)
                        .toUpperCase(),
                    toUint32Le(generalConstraintSet, 4)
                        .toString(16)
                        .toUpperCase(),
                ].join(".");
                this.#decoder.configure({
                    codec,
                    optimizeForLatency: true,
                });
                break;
            }
        }
        this.#config = data;
    }

    #decode(packet: ScrcpyMediaStreamDataPacket) {
        if (this.#decoder.state !== "configured") {
            return;
        }

        // WebCodecs requires configuration data to be with the first frame.
        // https://www.w3.org/TR/webcodecs-avc-codec-registration/#encodedvideochunk-type
        let data: Uint8Array;
        if (this.#config !== undefined) {
            data = new Uint8Array(
                this.#config.byteLength + packet.data.byteLength,
            );
            data.set(this.#config, 0);
            data.set(packet.data, this.#config.byteLength);
            this.#config = undefined;
        } else {
            data = packet.data;
        }

        this.#decoder.decode(
            new EncodedVideoChunk({
                // Treat `undefined` as `key`, otherwise won't decode.
                type: packet.keyframe === false ? "delta" : "key",
                timestamp: 0,
                data,
            }),
        );
    }

    dispose() {
        cancelAnimationFrame(this.#animationFrameId);
        if (this.#decoder.state !== "closed") {
            this.#decoder.close();
        }
    }
}
