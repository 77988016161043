import KeyEvent from './android/KeyEvent'
import UIEventsCode from '../UIEventsCode'

export const KeyToCodeMap = new Map([
   [UIEventsCode.Backquote, KeyEvent.KEYCODE_GRAVE],
   [UIEventsCode.Backslash, KeyEvent.KEYCODE_BACKSLASH],
   [UIEventsCode.BracketLeft, KeyEvent.KEYCODE_LEFT_BRACKET],
   [UIEventsCode.BracketRight, KeyEvent.KEYCODE_RIGHT_BRACKET],
   [UIEventsCode.Comma, KeyEvent.KEYCODE_COMMA],
   [UIEventsCode.Digit0, KeyEvent.KEYCODE_0],
   [UIEventsCode.Digit1, KeyEvent.KEYCODE_1],
   [UIEventsCode.Digit2, KeyEvent.KEYCODE_2],
   [UIEventsCode.Digit3, KeyEvent.KEYCODE_3],
   [UIEventsCode.Digit4, KeyEvent.KEYCODE_4],
   [UIEventsCode.Digit5, KeyEvent.KEYCODE_5],
   [UIEventsCode.Digit6, KeyEvent.KEYCODE_6],
   [UIEventsCode.Digit7, KeyEvent.KEYCODE_7],
   [UIEventsCode.Digit8, KeyEvent.KEYCODE_8],
   [UIEventsCode.Digit9, KeyEvent.KEYCODE_9],
   [UIEventsCode.Equal, KeyEvent.KEYCODE_EQUALS],
   [UIEventsCode.IntlRo, KeyEvent.KEYCODE_RO],
   [UIEventsCode.IntlYen, KeyEvent.KEYCODE_YEN],
   [UIEventsCode.KeyA, KeyEvent.KEYCODE_A],
   [UIEventsCode.KeyB, KeyEvent.KEYCODE_B],
   [UIEventsCode.KeyC, KeyEvent.KEYCODE_C],
   [UIEventsCode.KeyD, KeyEvent.KEYCODE_D],
   [UIEventsCode.KeyE, KeyEvent.KEYCODE_E],
   [UIEventsCode.KeyF, KeyEvent.KEYCODE_F],
   [UIEventsCode.KeyG, KeyEvent.KEYCODE_G],
   [UIEventsCode.KeyH, KeyEvent.KEYCODE_H],
   [UIEventsCode.KeyI, KeyEvent.KEYCODE_I],
   [UIEventsCode.KeyJ, KeyEvent.KEYCODE_J],
   [UIEventsCode.KeyK, KeyEvent.KEYCODE_K],
   [UIEventsCode.KeyL, KeyEvent.KEYCODE_L],
   [UIEventsCode.KeyM, KeyEvent.KEYCODE_M],
   [UIEventsCode.KeyN, KeyEvent.KEYCODE_N],
   [UIEventsCode.KeyO, KeyEvent.KEYCODE_O],
   [UIEventsCode.KeyP, KeyEvent.KEYCODE_P],
   [UIEventsCode.KeyQ, KeyEvent.KEYCODE_Q],
   [UIEventsCode.KeyR, KeyEvent.KEYCODE_R],
   [UIEventsCode.KeyS, KeyEvent.KEYCODE_S],
   [UIEventsCode.KeyT, KeyEvent.KEYCODE_T],
   [UIEventsCode.KeyU, KeyEvent.KEYCODE_U],
   [UIEventsCode.KeyV, KeyEvent.KEYCODE_V],
   [UIEventsCode.KeyW, KeyEvent.KEYCODE_W],
   [UIEventsCode.KeyX, KeyEvent.KEYCODE_X],
   [UIEventsCode.KeyY, KeyEvent.KEYCODE_Y],
   [UIEventsCode.KeyZ, KeyEvent.KEYCODE_Z],
   [UIEventsCode.Minus, KeyEvent.KEYCODE_MINUS],
   [UIEventsCode.Period, KeyEvent.KEYCODE_PERIOD],
   [UIEventsCode.Quote, KeyEvent.KEYCODE_APOSTROPHE],
   [UIEventsCode.Semicolon, KeyEvent.KEYCODE_SEMICOLON],
   [UIEventsCode.Slash, KeyEvent.KEYCODE_SLASH],
   [UIEventsCode.KanaMode, KeyEvent.KEYCODE_KANA],
   [UIEventsCode.Delete, KeyEvent.KEYCODE_FORWARD_DEL],
   [UIEventsCode.End, KeyEvent.KEYCODE_MOVE_END],
   [UIEventsCode.Help, KeyEvent.KEYCODE_HELP],
   [UIEventsCode.Home, KeyEvent.KEYCODE_MOVE_HOME],
   [UIEventsCode.Insert, KeyEvent.KEYCODE_INSERT],
   [UIEventsCode.PageDown, KeyEvent.KEYCODE_PAGE_DOWN],
   [UIEventsCode.PageUp, KeyEvent.KEYCODE_PAGE_UP],
   [UIEventsCode.AltLeft, KeyEvent.KEYCODE_ALT_LEFT],
   [UIEventsCode.AltRight, KeyEvent.KEYCODE_ALT_RIGHT],
   [UIEventsCode.Backspace, KeyEvent.KEYCODE_DEL],
   [UIEventsCode.CapsLock, KeyEvent.KEYCODE_CAPS_LOCK],
   [UIEventsCode.ControlLeft, KeyEvent.KEYCODE_CTRL_LEFT],
   [UIEventsCode.ControlRight, KeyEvent.KEYCODE_CTRL_RIGHT],
   [UIEventsCode.Enter, KeyEvent.KEYCODE_ENTER],
   [UIEventsCode.MetaLeft, KeyEvent.KEYCODE_META_LEFT],
   [UIEventsCode.MetaRight, KeyEvent.KEYCODE_META_RIGHT],
   [UIEventsCode.ShiftLeft, KeyEvent.KEYCODE_SHIFT_LEFT],
   [UIEventsCode.ShiftRight, KeyEvent.KEYCODE_SHIFT_RIGHT],
   [UIEventsCode.Space, KeyEvent.KEYCODE_SPACE],
   [UIEventsCode.Tab, KeyEvent.KEYCODE_TAB],
   [UIEventsCode.ArrowLeft, KeyEvent.KEYCODE_DPAD_LEFT],
   [UIEventsCode.ArrowUp, KeyEvent.KEYCODE_DPAD_UP],
   [UIEventsCode.ArrowRight, KeyEvent.KEYCODE_DPAD_RIGHT],
   [UIEventsCode.ArrowDown, KeyEvent.KEYCODE_DPAD_DOWN],

   [UIEventsCode.NumLock, KeyEvent.KEYCODE_NUM_LOCK],
   [UIEventsCode.Numpad0, KeyEvent.KEYCODE_NUMPAD_0],
   [UIEventsCode.Numpad1, KeyEvent.KEYCODE_NUMPAD_1],
   [UIEventsCode.Numpad2, KeyEvent.KEYCODE_NUMPAD_2],
   [UIEventsCode.Numpad3, KeyEvent.KEYCODE_NUMPAD_3],
   [UIEventsCode.Numpad4, KeyEvent.KEYCODE_NUMPAD_4],
   [UIEventsCode.Numpad5, KeyEvent.KEYCODE_NUMPAD_5],
   [UIEventsCode.Numpad6, KeyEvent.KEYCODE_NUMPAD_6],
   [UIEventsCode.Numpad7, KeyEvent.KEYCODE_NUMPAD_7],
   [UIEventsCode.Numpad8, KeyEvent.KEYCODE_NUMPAD_8],
   [UIEventsCode.Numpad9, KeyEvent.KEYCODE_NUMPAD_9],
   [UIEventsCode.NumpadAdd, KeyEvent.KEYCODE_NUMPAD_ADD],
   [UIEventsCode.NumpadComma, KeyEvent.KEYCODE_NUMPAD_COMMA],
   [UIEventsCode.NumpadDecimal, KeyEvent.KEYCODE_NUMPAD_DOT],
   [UIEventsCode.NumpadDivide, KeyEvent.KEYCODE_NUMPAD_DIVIDE],
   [UIEventsCode.NumpadEnter, KeyEvent.KEYCODE_NUMPAD_ENTER],
   [UIEventsCode.NumpadEqual, KeyEvent.KEYCODE_NUMPAD_EQUALS],
   [UIEventsCode.NumpadMultiply, KeyEvent.KEYCODE_NUMPAD_MULTIPLY],
   [UIEventsCode.NumpadParenLeft, KeyEvent.KEYCODE_NUMPAD_LEFT_PAREN],
   [UIEventsCode.NumpadParenRight, KeyEvent.KEYCODE_NUMPAD_RIGHT_PAREN],
   [UIEventsCode.NumpadSubtract, KeyEvent.KEYCODE_NUMPAD_SUBTRACT],

   [UIEventsCode.Escape, KeyEvent.KEYCODE_ESCAPE],
   [UIEventsCode.F1, KeyEvent.KEYCODE_F1],
   [UIEventsCode.F2, KeyEvent.KEYCODE_F2],
   [UIEventsCode.F3, KeyEvent.KEYCODE_F3],
   [UIEventsCode.F4, KeyEvent.KEYCODE_F4],
   [UIEventsCode.F5, KeyEvent.KEYCODE_F5],
   [UIEventsCode.F6, KeyEvent.KEYCODE_F6],
   [UIEventsCode.F7, KeyEvent.KEYCODE_F7],
   [UIEventsCode.F8, KeyEvent.KEYCODE_F8],
   [UIEventsCode.F9, KeyEvent.KEYCODE_F9],
   [UIEventsCode.F10, KeyEvent.KEYCODE_F10],
   [UIEventsCode.F11, KeyEvent.KEYCODE_F11],
   [UIEventsCode.F12, KeyEvent.KEYCODE_F12],
   [UIEventsCode.Fn, KeyEvent.KEYCODE_FUNCTION],
   [UIEventsCode.PrintScreen, KeyEvent.KEYCODE_SYSRQ],
   [UIEventsCode.Pause, KeyEvent.KEYCODE_BREAK],
])
