export const Attribute = {
   COMMAND: 'data-command',
   FULL_NAME: 'data-full-name',
   NAME: 'data-name',
   PID: 'data-pid',
   UDID: 'data-udid',
   URL: 'data-url',
   USE_PROXY: 'data-use-proxy',
   SECURE: 'data-secure',
   HOSTNAME: 'data-hostname',
   PORT: 'data-port',
   VALUE: 'data-value',
}
