import { ACTION } from "../../../common/Action";
import { ControlCenterCommand } from "../../../common/ControlCenterCommand";
import GoogDeviceDescriptor from "../../../types/GoogDeviceDescriptor";
import { ParamsDeviceTracker } from "../../../types/ParamsDeviceTracker";
import { ParamsStreamScrcpy } from "../../../types/ParamsStreamScrcpy";
import { Attribute } from "../../Attribute";
import { BaseClient } from "../../client/BaseClient";
import { HostTracker } from "../../client/HostTracker";
import { ClientsStats, DisplayCombinedInfo } from "../../client/StreamReceiver";
import { CommandControlMessage } from "../../controlMessage/CommandControlMessage";
import { ControlMessage } from "../../controlMessage/ControlMessage";
import { KeyCodeControlMessage } from "../../controlMessage/KeyCodeControlMessage";
import { DisplayInfo } from "../../DisplayInfo";
import {
    FeaturedInteractionHandler,
    InteractionHandlerListener,
} from "../../interactionHandler/FeaturedInteractionHandler";
import { BasePlayer, PlayerClass } from "../../player/BasePlayer";
import Size from "../../Size";
import { html } from "../../ui/HtmlTag";
import Util from "../../Util";
import VideoSettings from "../../VideoSettings";
import DeviceMessage from "../DeviceMessage";
import { KeyEventListener, KeyInputHandler } from "../KeyInputHandler";
import { GoogMoreBox } from "../toolbox/GoogMoreBox";
import { ConfigureScrcpy } from "./ConfigureScrcpy";
import { DeviceTracker } from "./DeviceTracker";
import { StreamReceiverScrcpy } from "./StreamReceiverScrcpy";

type StartParams = {
    udid: string;
    playerName?: string;
    player?: BasePlayer;
    fitToScreen?: boolean;
    videoSettings?: VideoSettings;
};

const TAG = "[StreamClientScrcpy]";

export class StreamClientScrcpy
    extends BaseClient<ParamsStreamScrcpy, never>
    implements KeyEventListener, InteractionHandlerListener
{
    public static ACTION = "stream";
    private static players: Map<string, PlayerClass> = new Map<
        string,
        PlayerClass
    >();

    public deviceView?: HTMLElement;
    private controlButtons?: HTMLElement;
    private deviceName = "";
    private clientId = -1;
    private clientsCount = -1;
    public joinedStream = false;
    private requestedVideoSettings?: VideoSettings;
    private touchHandler?: FeaturedInteractionHandler;
    private moreBox?: GoogMoreBox;
    private player?: BasePlayer;
    private readonly streamReceiver: StreamReceiverScrcpy;

    public static registerPlayer(playerClass: PlayerClass): void {
        if (playerClass.isSupported()) {
            this.players.set(playerClass.playerFullName, playerClass);
        }
    }

    public static getPlayers(): PlayerClass[] {
        return Array.from(this.players.values());
    }

    private static getPlayerClass(playerName: string): PlayerClass | undefined {
        let playerClass: PlayerClass | undefined;
        for (const value of StreamClientScrcpy.players.values()) {
            if (
                value.playerFullName === playerName ||
                value.playerCodeName === playerName
            ) {
                playerClass = value;
            }
        }
        return playerClass;
    }

    public static createPlayer(
        playerName: string,
        udid: string,
        displayInfo?: DisplayInfo,
    ): BasePlayer | undefined {
        const playerClass = this.getPlayerClass(playerName);
        if (!playerClass) {
            return;
        }
        return new playerClass(udid, displayInfo);
    }

    public static getFitToScreen(
        playerName: string,
        udid: string,
        displayInfo?: DisplayInfo,
    ): boolean {
        const playerClass = this.getPlayerClass(playerName);
        if (!playerClass) {
            return false;
        }
        return playerClass.getFitToScreenStatus(udid, displayInfo);
    }

    public static start(
        query: URLSearchParams | ParamsStreamScrcpy,
        streamReceiver?: StreamReceiverScrcpy,
        player?: BasePlayer,
        fitToScreen?: boolean,
        videoSettings?: VideoSettings,
    ): StreamClientScrcpy {
        if (query instanceof URLSearchParams) {
            const params = StreamClientScrcpy.parseParameters(query);
            return new StreamClientScrcpy(
                params,
                streamReceiver,
                player,
                fitToScreen,
                videoSettings,
            );
        } else {
            return new StreamClientScrcpy(
                query,
                streamReceiver,
                player,
                fitToScreen,
                videoSettings,
            );
        }
    }

    private static createVideoSettingsWithBounds(
        old: VideoSettings,
        newBounds: Size,
    ): VideoSettings {
        return new VideoSettings({
            crop: old.crop,
            bitrate: old.bitrate,
            bounds: newBounds,
            maxFps: old.maxFps,
            iFrameInterval: old.iFrameInterval,
            sendFrameMeta: old.sendFrameMeta,
            lockedVideoOrientation: old.lockedVideoOrientation,
            displayId: old.displayId,
            codecOptions: old.codecOptions,
            encoderName: old.encoderName,
        });
    }

    protected constructor(
        params: ParamsStreamScrcpy,
        streamReceiver?: StreamReceiverScrcpy,
        player?: BasePlayer,
        fitToScreen?: boolean,
        videoSettings?: VideoSettings,
    ) {
        super(params);
        if (streamReceiver) {
            this.streamReceiver = streamReceiver;
        } else {
            this.streamReceiver = new StreamReceiverScrcpy(this.params);
        }

        const { udid, player: playerName } = this.params;
        this.startStream({
            udid,
            player,
            playerName,
            fitToScreen: true,
            videoSettings,
        });
    }

    public static parseParameters(params: URLSearchParams): ParamsStreamScrcpy {
        const typedParams = super.parseParameters(params);
        const { action } = typedParams;
        if (action !== ACTION.STREAM_SCRCPY) {
            throw Error("Incorrect action");
        }
        return {
            ...typedParams,
            action,
            player: Util.parseString(params, "player", true),
            udid: Util.parseString(params, "udid", true),
            ws: Util.parseString(params, "ws", true),
        };
    }

    public OnDeviceMessage = (message: DeviceMessage): void => {
        if (this.moreBox) {
            this.moreBox.OnDeviceMessage(message);
        }
    };

    public onVideo = (data: ArrayBuffer): void => {
        if (!this.player) {
            return;
        }
        const STATE = BasePlayer.STATE;
        if (this.player.getState() === STATE.PAUSED) {
            this.player.play();
        }
        if (this.player.getState() === STATE.PLAYING) {
            this.player.pushFrame(new Uint8Array(data));
        }
    };

    public onClientsStats = (stats: ClientsStats): void => {
        this.deviceName = stats.deviceName;
        this.clientId = stats.clientId;
        this.setTitle(`Stream ${this.deviceName}`);
    };

    public onDisplayInfo = (infoArray: DisplayCombinedInfo[]): void => {
        if (!this.player) {
            return;
        }
        let currentSettings = this.player.getVideoSettings();
        const displayId = currentSettings.displayId;
        const info = infoArray.find((value) => {
            return value.displayInfo.displayId === displayId;
        });
        if (!info) {
            return;
        }
        if (this.player.getState() === BasePlayer.STATE.PAUSED) {
            this.player.play();
        }
        const { videoSettings, screenInfo } = info;
        this.player.setDisplayInfo(info.displayInfo);
        if (!videoSettings || !screenInfo) {
            this.joinedStream = true;
            this.sendMessage(
                CommandControlMessage.createSetVideoSettingsCommand(
                    currentSettings,
                ),
            );
            return;
        }

        this.clientsCount = info.connectionCount;
        let min = VideoSettings.copy(videoSettings);
        const oldInfo = this.player.getScreenInfo();
        if (!screenInfo.equals(oldInfo)) {
            this.player.setScreenInfo(screenInfo);
        }

        if (!videoSettings.equals(currentSettings)) {
            this.applyNewVideoSettings(
                videoSettings,
                videoSettings.equals(this.requestedVideoSettings),
            );
        }
        if (!oldInfo) {
            const bounds = currentSettings.bounds;
            const videoSize: Size = screenInfo.videoSize;
            const onlyOneClient = this.clientsCount === 0;
            const smallerThenCurrent =
                bounds &&
                (bounds.width < videoSize.width ||
                    bounds.height < videoSize.height);
            if (onlyOneClient || smallerThenCurrent) {
                min = currentSettings;
            }
            const minBounds = currentSettings.bounds?.intersect(min.bounds);
            if (minBounds && !minBounds.equals(min.bounds)) {
                min = StreamClientScrcpy.createVideoSettingsWithBounds(
                    min,
                    minBounds,
                );
            }
        }
        if (!min.equals(videoSettings) || !this.joinedStream) {
            this.joinedStream = true;
            this.sendMessage(
                CommandControlMessage.createSetVideoSettingsCommand(min),
            );
        }
    };

    public onDisconnected = (): void => {
        this.streamReceiver.off("deviceMessage", this.OnDeviceMessage);
        this.streamReceiver.off("video", this.onVideo);
        this.streamReceiver.off("clientsStats", this.onClientsStats);
        this.streamReceiver.off("displayInfo", this.onDisplayInfo);
        this.streamReceiver.off("disconnected", this.onDisconnected);
        this.streamReceiver.off("connected", this.onConnected);

        this.touchHandler?.release();
        this.touchHandler = undefined;

        this.emit("disconnected", undefined as never);
    };

    public onConnected = (): void => {
        this.emit("connected", undefined as never);
    };

    public startStream({
        udid,
        player,
        playerName,
        videoSettings,
        fitToScreen,
    }: StartParams): void {
        if (!udid) {
            throw Error(`Invalid udid value: "${udid}"`);
        }

        if (!player) {
            if (typeof playerName !== "string") {
                throw Error("Must provide BasePlayer instance or playerName");
            }
            let displayInfo: DisplayInfo | undefined;
            if (this.streamReceiver && videoSettings) {
                displayInfo = this.streamReceiver.getDisplayInfo(
                    videoSettings.displayId,
                );
            }
            const p = StreamClientScrcpy.createPlayer(
                playerName,
                udid,
                displayInfo,
            );
            if (!p) {
                throw Error(`Unsupported player: "${playerName}"`);
            }
            if (typeof fitToScreen !== "boolean") {
                fitToScreen = StreamClientScrcpy.getFitToScreen(
                    playerName,
                    udid,
                    displayInfo,
                );
            }
            player = p;
            console.log(player);
        }
        this.player = player;

        if (!videoSettings) {
            videoSettings = player.getVideoSettings();
        }

        player.pause();

        if (fitToScreen) {
            const newBounds = this.getMaxSize();
            if (newBounds) {
                videoSettings =
                    StreamClientScrcpy.createVideoSettingsWithBounds(
                        videoSettings,
                        newBounds,
                    );
            }
        }

        this.applyNewVideoSettings(videoSettings, false);

        const streamReceiver = this.streamReceiver;
        streamReceiver.on("deviceMessage", this.OnDeviceMessage);
        streamReceiver.on("video", this.onVideo);
        streamReceiver.on("clientsStats", this.onClientsStats);
        streamReceiver.on("displayInfo", this.onDisplayInfo);
        streamReceiver.on("disconnected", this.onDisconnected);
        streamReceiver.on("connected", this.onConnected);
        console.log(TAG, player.getName(), udid);
    }

    public setParent(parent: HTMLElement): void {
        this.player?.setParent(parent);
    }

    public sendMessage(message: ControlMessage): void {
        this.streamReceiver.sendEvent(message);
    }

    public getDeviceName(): string {
        return this.deviceName;
    }

    public setHandleKeyboardEvents(enabled: boolean): void {
        if (enabled) {
            KeyInputHandler.addEventListener(this);
        } else {
            KeyInputHandler.removeEventListener(this);
        }
    }

    public onKeyEvent(event: KeyCodeControlMessage): void {
        this.sendMessage(event);
    }

    public sendNewVideoSetting(videoSettings: VideoSettings): void {
        this.requestedVideoSettings = videoSettings;
        this.sendMessage(
            CommandControlMessage.createSetVideoSettingsCommand(videoSettings),
        );
    }

    public getClientId(): number {
        return this.clientId;
    }

    public getClientsCount(): number {
        return this.clientsCount;
    }

    public getMaxSize(): Size | undefined {
        if (!this.controlButtons) {
            return;
        }
        const body = document.body;
        const width =
            (body.clientWidth - this.controlButtons.clientWidth) & ~15;
        const height = body.clientHeight & ~15;
        return new Size(width, height);
    }

    private applyNewVideoSettings(
        videoSettings: VideoSettings,
        saveToStorage: boolean,
    ): void {
        let fitToScreen = false;

        // TODO: create control (switch/checkbox) instead
        if (
            videoSettings.bounds &&
            videoSettings.bounds.equals(this.getMaxSize())
        ) {
            fitToScreen = true;
        }
        if (this.player) {
            this.player.setVideoSettings(
                videoSettings,
                fitToScreen,
                saveToStorage,
            );
        }
    }

    public static createEntryForDeviceList(
        descriptor: GoogDeviceDescriptor,
        blockClass: string,
        fullName: string,
        params: ParamsDeviceTracker,
    ): HTMLElement | DocumentFragment | undefined {
        const hasPid = descriptor.pid !== -1;
        if (hasPid) {
            const configureButtonId = `configure_${Util.escapeUdid(
                descriptor.udid,
            )}`;
            const e = html`<div class="stream ${blockClass}">
                <button
                    ${Attribute.UDID}="${descriptor.udid}"
                    ${Attribute.COMMAND}="${ControlCenterCommand.CONFIGURE_STREAM}"
                    ${Attribute.FULL_NAME}="${fullName}"
                    ${Attribute.SECURE}="${params.secure}"
                    ${Attribute.HOSTNAME}="${params.hostname}"
                    ${Attribute.PORT}="${params.port}"
                    ${Attribute.USE_PROXY}="${params.useProxy}"
                    id="${configureButtonId}"
                    class="active action-button"
                >
                    Configure stream
                </button>
            </div>`;
            const a = e.content.getElementById(configureButtonId);
            a && (a.onclick = this.onConfigureStreamClick);
            return e.content;
        }
        return;
    }

    private static onConfigureStreamClick = (event: MouseEvent): void => {
        const button = event.currentTarget as HTMLAnchorElement;
        const udid = Util.parseStringEnv(
            button.getAttribute(Attribute.UDID) || "",
        );
        const fullName = button.getAttribute(Attribute.FULL_NAME);
        const secure =
            Util.parseBooleanEnv(
                button.getAttribute(Attribute.SECURE) || undefined,
            ) || false;
        const hostname =
            Util.parseStringEnv(
                button.getAttribute(Attribute.HOSTNAME) || undefined,
            ) || "";
        const port = Util.parseIntEnv(
            button.getAttribute(Attribute.PORT) || undefined,
        );
        const useProxy = Util.parseBooleanEnv(
            button.getAttribute(Attribute.USE_PROXY) || undefined,
        );
        if (!udid) {
            throw Error(`Invalid udid value: "${udid}"`);
        }
        if (typeof port !== "number") {
            throw Error(`Invalid port type: ${typeof port}`);
        }
        const tracker = DeviceTracker.getInstance({
            type: "android",
            secure,
            hostname,
            port,
            useProxy,
        });
        const descriptor = tracker.getDescriptorByUdid(udid);
        if (!descriptor) {
            return;
        }
        event.preventDefault();
        const elements = document.getElementsByName(
            `${DeviceTracker.AttributePrefixInterfaceSelectFor}${fullName}`,
        );
        if (!elements || !elements.length) {
            return;
        }
        const select = elements[0] as HTMLSelectElement;
        const optionElement = select.options[select.selectedIndex];
        const ws = optionElement.getAttribute(Attribute.URL);
        const name = optionElement.getAttribute(Attribute.NAME);
        if (!ws || !name) {
            return;
        }
        const options: ParamsStreamScrcpy = {
            udid,
            ws,
            player: "",
            action: ACTION.STREAM_SCRCPY,
            secure,
            hostname,
            port,
            useProxy,
        };
        const dialog = new ConfigureScrcpy(tracker, descriptor, options);
        dialog.on("closed", StreamClientScrcpy.onConfigureDialogClosed);
    };

    private static onConfigureDialogClosed = (event: {
        dialog: ConfigureScrcpy;
        result: boolean;
    }): void => {
        event.dialog.off("closed", StreamClientScrcpy.onConfigureDialogClosed);
        if (event.result) {
            HostTracker.getInstance().destroy();
        }
    };
}
