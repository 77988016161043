import { sendGAEvent } from "@next/third-parties/google";
import {
    ADB_SYNC_MAX_PACKET_SIZE,
    AdbDaemonDevice,
    AdbSubprocessWaitResult,
    encodeUtf8,
} from "@yume-chan/adb";
import { AdbDaemonWebUsbDevice } from "@yume-chan/adb-daemon-webusb";
import { AdbScrcpyClient, AdbScrcpyOptionsLatest } from "@yume-chan/adb-scrcpy";
import { VERSION } from "@yume-chan/fetch-scrcpy-server";
import {
    Float32PcmPlayer,
    Float32PlanerPcmPlayer,
    Int16PcmPlayer,
    PcmPlayer,
} from "@yume-chan/pcm-player";
import {
    AndroidScreenPowerMode,
    CodecOptions,
    DEFAULT_SERVER_PATH,
    ScrcpyAudioCodec,
    ScrcpyDeviceMessageType,
    ScrcpyHoverHelper,
    ScrcpyInstanceId,
    ScrcpyLogLevel,
    ScrcpyMediaStreamPacket,
    ScrcpyOptionsLatest,
    ScrcpyVideoCodecId,
    clamp,
    h264ParseConfiguration,
    h265ParseConfiguration,
} from "@yume-chan/scrcpy";
import { ScrcpyVideoDecoder } from "@yume-chan/scrcpy-decoder-tinyh264";
import {
    Consumable,
    DistributionStream,
    InspectStream,
    ReadableStream,
    WritableStream,
} from "@yume-chan/stream-extra";
import { action, autorun, makeAutoObservable, runInAction } from "mobx";
import Size from "../../features/local-screen-streaming/app/Size";
import VideoSettings from "../../features/local-screen-streaming/app/VideoSettings";
import { StreamClientScrcpy } from "../../features/local-screen-streaming/app/googDevice/client/StreamClientScrcpy";
import { MsePlayer } from "../../features/local-screen-streaming/app/player/MsePlayer";
import { ACTION } from "../../features/local-screen-streaming/common/Action";
import { ScrcpyServer } from "../../features/local-screen-streaming/server/goog-device/ScrcpyServer";
import { GLOBAL_STATE } from "../../state";
import { ProgressStream } from "../../utils";
import { AacDecodeStream, OpusDecodeStream } from "./audio-decode-stream";
import { fetchServer } from "./fetch-server";
import {
    AoaKeyboardInjector,
    KeyboardInjector,
    ScrcpyKeyboardInjector,
} from "./input";
import { MatroskaMuxingRecorder, RECORD_STATE } from "./recorder";
import { SCRCPY_SETTINGS_FILENAME, SETTING_STATE } from "./settings";

enum PID_DETECTION {
    UNKNOWN,
    PIDOF,
    GREP_PS,
    GREP_PS_A,
    LS_PROC,
}

export interface NetInterface {
    name: string;
    ipv4: string;
}

const NOOP = () => {
    // no-op
};

export const SERVER_PACKAGE = "com.genymobile.scrcpy.Server";
export const SERVER_PORT = 8886;
export const SERVER_VERSION = "1.19-ws5";

export const SERVER_TYPE = "web";

export const LOG_LEVEL = "ERROR";

let SCRCPY_LISTENS_ON_ALL_INTERFACES;
/// #if SCRCPY_LISTENS_ON_ALL_INTERFACES
SCRCPY_LISTENS_ON_ALL_INTERFACES = true;
/// #else
SCRCPY_LISTENS_ON_ALL_INTERFACES = false;
/// #endif

const ARGUMENTS = [
    SERVER_VERSION,
    SERVER_TYPE,
    LOG_LEVEL,
    SERVER_PORT,
    SCRCPY_LISTENS_ON_ALL_INTERFACES,
];

export const SERVER_PROCESS_NAME = "app_process";

export const ARGS_STRING = `/ ${SERVER_PACKAGE} ${ARGUMENTS.join(
    " ",
)} 2>&1 > /dev/null`;

export class ScrcpyPageState {
    running = false;

    fullScreenContainer: HTMLDivElement | null = null;
    wiredStreamContainer: HTMLDivElement | null = null;
    wirelessStreamContainer: HTMLDivElement | null = null;

    isFullScreen = false;

    logVisible = false;
    log: string[] = [];
    demoModeVisible = false;
    navigationBarVisible = true;

    width = 0;
    height = 0;
    rotation = 0;

    aspectRatio: "16:9" | "1:1" | "fill" = "16:9";

    get rotatedWidth() {
        return STATE.rotation & 1 ? STATE.height : STATE.width;
    }
    get rotatedHeight() {
        return STATE.rotation & 1 ? STATE.width : STATE.height;
    }

    client: AdbScrcpyClient | undefined = undefined;
    hoverHelper: ScrcpyHoverHelper | undefined = undefined;
    keyboard: KeyboardInjector | undefined = undefined;
    audioPlayer: PcmPlayer<unknown> | undefined = undefined;

    async pushServer() {
        const serverBuffer = await fetchServer();
        await AdbScrcpyClient.pushServer(
            GLOBAL_STATE.adb!,
            new ReadableStream<Consumable<Uint8Array>>({
                start(controller) {
                    controller.enqueue(new Consumable(serverBuffer));
                    controller.close();
                },
            }),
        );
    }

    decoder: ScrcpyVideoDecoder | undefined = undefined;
    fpsCounterIntervalId: any = undefined;
    fps = "0";

    connecting = false;
    serverTotalSize = 0;
    serverDownloadedSize = 0;
    debouncedServerDownloadedSize = 0;
    serverDownloadSpeed = 0;
    serverUploadedSize = 0;
    debouncedServerUploadedSize = 0;
    serverUploadSpeed = 0;
    pidDetectionVariant = PID_DETECTION.UNKNOWN;
    pid = -1;
    netInterface: NetInterface | undefined = undefined;
    wirelessStreamClient: StreamClientScrcpy | undefined = undefined;

    constructor() {
        makeAutoObservable(this, {
            start: false,
            stop: action.bound,
            dispose: action.bound,
            setFullScreenContainer: action.bound,
            setWiredStreamContainer: action.bound,
            setWirelessStreamContainer: action.bound,
            clientPositionToDevicePosition: false,
        });

        autorun(() => {
            if (!GLOBAL_STATE.adb) {
                this.dispose();
            }
        });

        if (typeof document === "object") {
            document.addEventListener("fullscreenchange", () => {
                if (!document.fullscreenElement) {
                    runInAction(() => {
                        this.isFullScreen = false;
                    });
                }
            });
        }

        autorun(() => {
            if (this.wiredStreamContainer && this.decoder) {
                while (this.wiredStreamContainer.firstChild) {
                    this.wiredStreamContainer.firstChild.remove();
                }
                this.wiredStreamContainer.appendChild(this.decoder.renderer);
            }
        });

        autorun(() => {
            if (this.wirelessStreamContainer && this.wirelessStreamClient) {
                while (this.wirelessStreamContainer.firstChild) {
                    this.wirelessStreamContainer.firstChild.remove();
                }
                this.wirelessStreamClient.setParent(
                    this.wirelessStreamContainer,
                );
            }
        });
    }

    public async runShellCommandAdb(
        device: AdbDaemonDevice,
        command: string,
    ): Promise<AdbSubprocessWaitResult> {
        return await GLOBAL_STATE.adb!.subprocess.spawnAndWait(command);
    }

    public async runShellCommandAdbForResult(
        device: AdbDaemonDevice,
        command: string,
    ): Promise<string> {
        console.log("Running command", command);
        return (await GLOBAL_STATE.adb!.subprocess.spawnAndWait(command))
            .stdout;
    }

    private async executedWithoutError(
        device: AdbDaemonDevice,
        command: string,
    ): Promise<boolean> {
        return this.runShellCommandAdb(device, command)
            .then((adbResult) => {
                const err = parseInt(adbResult.stdout, 10);
                return err === 0;
            })
            .catch(() => {
                return false;
            });
    }

    private async hasPs(device: AdbDaemonDevice): Promise<boolean> {
        return this.executedWithoutError(
            device,
            "ps | grep init 2>&1 >/dev/null; echo $?",
        );
    }

    private async hasPs_A(device: AdbDaemonDevice): Promise<boolean> {
        return this.executedWithoutError(
            device,
            "ps -A | grep init 2>&1 >/dev/null; echo $?",
        );
    }

    private async hasPidOf(device: AdbDaemonDevice): Promise<boolean> {
        const ok = await this.executedWithoutError(
            device,
            "which pidof 2>&1 >/dev/null && echo $?",
        );
        if (!ok) {
            return false;
        }
        return this.runShellCommandAdb(device, "echo $PPID; pidof init")
            .then((adbResult) => {
                const pids = adbResult.stdout
                    .split("\n")
                    .filter((a) => a.length);
                if (pids.length < 2) {
                    return false;
                }
                const parentPid = pids[0].replace("\r", "");
                const list = pids[1].split(" ");
                if (list.includes(parentPid)) {
                    return false;
                }
                return list.includes("1");
            })
            .catch(() => {
                return false;
            });
    }

    private async findDetectionVariant(
        device: AdbDaemonDevice,
    ): Promise<PID_DETECTION> {
        if (await this.hasPidOf(device)) {
            return PID_DETECTION.PIDOF;
        }
        if (await this.hasPs_A(device)) {
            return PID_DETECTION.GREP_PS_A;
        }
        if (await this.hasPs(device)) {
            return PID_DETECTION.GREP_PS;
        }
        return PID_DETECTION.LS_PROC;
    }

    getServerPid = async (
        device: AdbDaemonDevice,
    ): Promise<number[] | undefined> => {
        // if (!device.isConnected()) {
        //     return;
        // }
        const list = await this.getPidOf(device, SERVER_PROCESS_NAME);
        if (!Array.isArray(list) || !list.length) {
            return;
        }
        const serverPid: number[] = [];
        const promises = list.map((pid) => {
            return this.runShellCommandAdb(
                device,
                `cat /proc/${pid}/cmdline`,
            ).then(async (adbResult) => {
                const args = adbResult.stdout.split("\0");
                if (!args.length || args[0] !== SERVER_PROCESS_NAME) {
                    return;
                }
                let first = args[0];
                while (args.length && first !== SERVER_PACKAGE) {
                    args.shift();
                    first = args[0];
                }
                if (args.length < 3) {
                    return;
                }
                const versionString = args[1];
                if (versionString === SERVER_VERSION) {
                    serverPid.push(pid);
                } else {
                    // const currentVersion = new ServerVersion(versionString);
                    // if (currentVersion.isCompatible()) {
                    //     const desired = new ServerVersion(SERVER_VERSION);
                    //     if (desired.gt(currentVersion)) {
                    //         console.log(
                    //             device.TAG,
                    //             `Found old server version running (PID: ${pid}, Version: ${versionString})`,
                    //         );
                    //         console.log(device.TAG, "Perform kill now");
                    //         device.killProcess(pid);
                    //     }
                    // }
                }
                return;
            });
        });
        await Promise.all(promises);
        return serverPid;
    };

    public async getPidOf(
        device: AdbDaemonDevice,
        processName: string,
    ): Promise<number[] | undefined> {
        if (this.pidDetectionVariant === PID_DETECTION.UNKNOWN) {
            this.pidDetectionVariant = await this.findDetectionVariant(device);
        }
        switch (this.pidDetectionVariant) {
            case PID_DETECTION.PIDOF:
                return this.pidOf(device, processName);
            case PID_DETECTION.GREP_PS:
                return this.grepPs(device, processName);
            case PID_DETECTION.GREP_PS_A:
                return this.grepPs_A(device, processName);
            default:
                return this.listProc(device, processName);
        }
    }

    private async listProc(
        device: AdbDaemonDevice,
        processName: string,
    ): Promise<number[]> {
        const find = `find /proc -maxdepth 2 -name cmdline  2>/dev/null`;
        const adbResult = await this.runShellCommandAdb(
            device,
            `for L in \`${find}\`; do grep -sae '^${processName}' $L 2>&1 >/dev/null && echo $L; done`,
        );
        const lines = adbResult.stdout;
        const re = /\/proc\/([0-9]+)\/cmdline/;
        const list: number[] = [];
        lines.split("\n").map((line) => {
            const trim = line.trim();
            const m = trim.match(re);
            if (m) {
                list.push(parseInt(m[1], 10));
            }
        });
        return list;
    }

    private async pidOf(
        device: AdbDaemonDevice,
        processName: string,
    ): Promise<number[]> {
        return this.runShellCommandAdb(device, `pidof ${processName}`)
            .then((output) => {
                return output.stdout
                    .split(" ")
                    .map((pid) => parseInt(pid, 10))
                    .filter((num) => !isNaN(num));
            })
            .catch(() => {
                return [];
            });
    }

    private filterPsOutput(processName: string, output: string): number[] {
        const list: number[] = [];
        const processes = output.split("\n");
        processes.map((line) => {
            const cols = line
                .trim()
                .split(" ")
                .filter((item) => item.length);
            if (cols[cols.length - 1] === processName) {
                const pid = parseInt(cols[1], 10);
                if (!isNaN(pid)) {
                    list.push(pid);
                }
            }
        });
        return list;
    }

    private async grepPs_A(
        device: AdbDaemonDevice,
        processName: string,
    ): Promise<number[]> {
        return this.runShellCommandAdb(device, `ps -A | grep ${processName}`)
            .then((adbResult) => {
                return this.filterPsOutput(processName, adbResult.stdout);
            })
            .catch(() => {
                return [];
            });
    }

    private async grepPs(
        device: AdbDaemonDevice,
        processName: string,
    ): Promise<number[]> {
        return this.runShellCommandAdb(device, `ps | grep ${processName}`)
            .then((output) => {
                return this.filterPsOutput(processName, output.stdout);
            })
            .catch(() => {
                return [];
            });
    }

    private interfacesSort = (a: NetInterface, b: NetInterface): number => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    };

    public async getNetInterfaces(
        device: AdbDaemonDevice,
    ): Promise<NetInterface[]> {
        const list: NetInterface[] = [];
        const adbResult = await this.runShellCommandAdb(
            device,
            `ip -4 -f inet -o a | grep 'scope global'`,
        );
        const lines = adbResult.stdout.split("\n").filter((i: string) => !!i);
        lines.forEach((value: string) => {
            const temp = value.split(" ").filter((i: string) => !!i);
            const name = temp[1];
            const ipAndMask = temp[3];
            const ipv4 = ipAndMask.split("/")[0];
            list.push({ name, ipv4 });
        });
        return list.sort(this.interfacesSort);
    }

    public killProcess(
        device: AdbDaemonDevice,
        pid: number,
    ): Promise<AdbSubprocessWaitResult> {
        const command = `kill ${pid}`;
        return this.runShellCommandAdb(device, command);
    }

    public async killServer(
        device: AdbDaemonDevice,
        pid: number,
    ): Promise<void> {
        const realPid = await this.getServerPid(device);
        if (typeof realPid !== "number") {
            return;
        }
        if (realPid !== pid) {
            console.error(
                `Requested to kill server with PID ${pid}. Real server PID is ${realPid}.`,
            );
        }
        try {
            const output = await this.killProcess(device, realPid);
            if (output) {
                console.log(`kill server: "${output}"`);
            }
        } catch (error: any) {
            console.error(`Error: ${error.message}`);
            throw error;
        }
    }

    public async startServer(
        device: AdbDaemonDevice,
    ): Promise<number | undefined> {
        // const pid = await this.getServerPid(device);
        // if (typeof pid === "number") {
        //     return pid;
        // }
        try {
            const output = await ScrcpyServer.run(GLOBAL_STATE.device!);
            if (output) {
                console.log(`start server: "${output}"`);
            }
            const pids = this.getServerPid(device);
            if (!Array.isArray(pids) || !pids.length) {
                return -1;
            } else {
                return pids[0];
            }
        } catch (error: any) {
            console.error(`Error: ${error.message}`);
            throw error;
        }
    }

    directStartWs = async (ip: string) => {
        StreamClientScrcpy.registerPlayer(MsePlayer);
        this.wirelessStreamClient = StreamClientScrcpy.start(
            {
                ws: `ws://${ip}:8886/`,
                action: ACTION.STREAM_SCRCPY,
                udid: "device",
                player: "mse",
            },
            undefined,
            undefined,
            undefined,
            new VideoSettings({
                lockedVideoOrientation: -1,
                bitrate: 7340032,
                maxFps: 60,
                iFrameInterval: 10,
                bounds: new Size(SETTING_STATE.maxSize, SETTING_STATE.maxSize),
                crop: SETTING_STATE.crop,
                sendFrameMeta: false,
            }),
        );
        this.wirelessStreamClient.on("disconnected", () => {
            GLOBAL_STATE.errorDialogWithCloseButton = true;
            GLOBAL_STATE.showErrorDialog(
                "Failed to wirelessly connect to your device.\n\nTroubleshooting tips:\n- Make sure your device is connected to the same network as your computer.\n- If you are connected to the same network, then your network may be blocking peer-to-peer connections. Try wireless streaming with both devices connected to a different network.\n\nTo try again, close this window and relaunch the cast from the main USB casting tool website.",
            );
        });
        let hasConnected = false;
        this.wirelessStreamClient.on("connected", () => {
            hasConnected = true;
        });
        setTimeout(() => {
            if (!hasConnected && this.wirelessStreamClient) {
                console.log("Disconnected due to timeout");
                this.wirelessStreamClient.onDisconnected();
            }
        }, 2000);
    };

    startWs = async () => {
        if (GLOBAL_STATE.device!.name?.toLowerCase().startsWith("pico")) return;

        this.pid = (await this.startServer(GLOBAL_STATE.device!)) || -1;

        const netInterfaces = await this.getNetInterfaces(GLOBAL_STATE.device!);
        console.log("Net Interfaces", netInterfaces);
        if (netInterfaces.length) {
            this.netInterface = netInterfaces[0];
        }

        // TODO probably need to clean up the client if it's already running and things like that
        StreamClientScrcpy.registerPlayer(MsePlayer);
        this.wirelessStreamClient = StreamClientScrcpy.start(
            {
                ws: `ws://${this.netInterface!.ipv4}:8886/`,
                action: ACTION.STREAM_SCRCPY,
                udid: GLOBAL_STATE.device!.serial,
                player: "mse",
            },
            undefined,
            undefined,
            undefined,
            new VideoSettings({
                lockedVideoOrientation: -1,
                bitrate: 7340032,
                maxFps: 60,
                iFrameInterval: 10,
                bounds: new Size(SETTING_STATE.maxSize, SETTING_STATE.maxSize),
                crop: SETTING_STATE.crop,
                sendFrameMeta: false,
            }),
        );
    };

    startWsSeparateWindow = async () => {
        if (GLOBAL_STATE.device!.name?.toLowerCase().startsWith("pico")) return;

        this.pid = (await this.startServer(GLOBAL_STATE.device!)) || -1;

        const netInterfaces = await this.getNetInterfaces(GLOBAL_STATE.device!);
        console.log("Net Interfaces", netInterfaces);
        if (netInterfaces.length) {
            this.netInterface = netInterfaces[0];
        }

        let host = "http://35.232.78.131/";
        if (window.location.host.startsWith("localhost")) {
            host = `http://${window.location.host}/`;
        }

        const url = new URL(host);
        url.searchParams.append("ip", this.netInterface?.ipv4 || "unknown");
        url.searchParams.append("mode", "WIRELESS");
        url.searchParams.append("model", SETTING_STATE.deviceModel);

        // Features of the popup window: width, height, and other features
        const features = `top=150,left=150,width=${
            window.screen.width - 300
        },height=${window.screen.height - 300}`;

        // Open a new popup window
        window.open(url.href, "_blank", features);
    };

    start = async () => {
        if (!GLOBAL_STATE.adb) {
            return;
        }

        try {
            if (!SETTING_STATE.clientSettings.decoder) {
                throw new Error("No available decoder");
            }

            runInAction(() => {
                this.serverTotalSize = 0;
                this.serverDownloadedSize = 0;
                this.debouncedServerDownloadedSize = 0;
                this.serverUploadedSize = 0;
                this.debouncedServerUploadedSize = 0;
                this.connecting = true;
            });

            let intervalId = setInterval(
                action(() => {
                    this.serverDownloadSpeed =
                        this.serverDownloadedSize -
                        this.debouncedServerDownloadedSize;
                    this.debouncedServerDownloadedSize =
                        this.serverDownloadedSize;
                }),
                1000,
            );

            let serverBuffer: Uint8Array;
            try {
                serverBuffer = await fetchServer(
                    action(([downloaded, total]) => {
                        this.serverDownloadedSize = downloaded;
                        this.serverTotalSize = total;
                    }),
                );
                runInAction(() => {
                    this.serverDownloadSpeed =
                        this.serverDownloadedSize -
                        this.debouncedServerDownloadedSize;
                    this.debouncedServerDownloadedSize =
                        this.serverDownloadedSize;
                });
            } finally {
                clearInterval(intervalId);
            }

            intervalId = setInterval(
                action(() => {
                    this.serverUploadSpeed =
                        this.serverUploadedSize -
                        this.debouncedServerUploadedSize;
                    this.debouncedServerUploadedSize = this.serverUploadedSize;
                }),
                1000,
            );

            try {
                await AdbScrcpyClient.pushServer(
                    GLOBAL_STATE.adb!,
                    new ReadableStream<Consumable<Uint8Array>>({
                        start(controller) {
                            controller.enqueue(new Consumable(serverBuffer));
                            controller.close();
                        },
                    })
                        // In fact `pushServer` will pipe the stream through a DistributionStream,
                        // but without this pipeThrough, the progress will not be updated.
                        .pipeThrough(
                            new DistributionStream(ADB_SYNC_MAX_PACKET_SIZE),
                        )
                        .pipeThrough(
                            new ProgressStream(
                                action((progress) => {
                                    this.serverUploadedSize = progress;
                                }),
                            ),
                        ),
                );

                runInAction(() => {
                    this.serverUploadSpeed =
                        this.serverUploadedSize -
                        this.debouncedServerUploadedSize;
                    this.debouncedServerUploadedSize = this.serverUploadedSize;
                });
            } finally {
                clearInterval(intervalId);
            }

            const decoderDefinition =
                SETTING_STATE.decoders.find(
                    (x) => x.key === SETTING_STATE.clientSettings.decoder,
                ) ?? SETTING_STATE.decoders[0];

            const videoCodecOptions = new CodecOptions();
            if (!SETTING_STATE.clientSettings.ignoreDecoderCodecArgs) {
                const capability =
                    decoderDefinition.Constructor.capabilities[
                        SETTING_STATE.settings.videoCodec!
                    ];
                if (capability) {
                    videoCodecOptions.value.profile = capability.maxProfile;
                    videoCodecOptions.value.level = capability.maxLevel;
                }
            }

            // Disabled due to https://github.com/Genymobile/scrcpy/issues/2841
            // Less recording delay
            // codecOptions.value.iFrameInterval = 1;
            // Less latency
            // codecOptions.value.intraRefreshPeriod = 10000;

            const options = new AdbScrcpyOptionsLatest(
                new ScrcpyOptionsLatest({
                    ...SETTING_STATE.settings,
                    logLevel: ScrcpyLogLevel.Debug,
                    scid: ScrcpyInstanceId.random(),
                    sendDeviceMeta: false,
                    sendDummyByte: false,
                    videoCodecOptions,
                    audioCodecOptions: undefined, // Set to undefined, otherwise this was being serialized to [object Object] and causing scrcpy to fail to initialize on device
                }),
            );

            runInAction(() => {
                this.log = [];
                this.log.push(`[client] Server version: ${VERSION}`);
                this.log.push(
                    `[client] Server arguments: ${options
                        .serialize()
                        .join(" ")}`,
                );
            });

            const client = await AdbScrcpyClient.start(
                GLOBAL_STATE.adb!,
                DEFAULT_SERVER_PATH,
                VERSION,
                options,
            );

            client.stdout.pipeTo(
                new WritableStream<string>({
                    write: action((line) => {
                        this.log.push(line);
                    }),
                }),
            );

            const sync = await GLOBAL_STATE.adb!.sync();
            try {
                await sync.write({
                    filename: SCRCPY_SETTINGS_FILENAME,
                    file: new ReadableStream<Consumable<Uint8Array>>({
                        start(controller) {
                            controller.enqueue(
                                new Consumable(
                                    encodeUtf8(
                                        JSON.stringify({
                                            settings: SETTING_STATE.settings,
                                            clientSettings:
                                                SETTING_STATE.clientSettings,
                                        }),
                                    ),
                                ),
                            );
                            controller.close();
                        },
                    }),
                });
            } finally {
                sync.dispose();
            }

            RECORD_STATE.recorder = new MatroskaMuxingRecorder();

            client.videoStream!.then(({ stream, metadata }) => {
                runInAction(() => {
                    RECORD_STATE.recorder.videoMetadata = metadata;
                });

                const decoder = new decoderDefinition.Constructor(
                    metadata.codec,
                    GLOBAL_STATE.device?.name,
                );

                runInAction(() => {
                    this.decoder = decoder;

                    let lastFrameRendered = 0;
                    let lastFrameSkipped = 0;
                    this.fpsCounterIntervalId = setInterval(
                        action(() => {
                            const deltaRendered =
                                decoder.frameRendered - lastFrameRendered;
                            const deltaSkipped =
                                decoder.frameSkipped - lastFrameSkipped;
                            // prettier-ignore
                            this.fps = `${
                            deltaRendered
                        }${
                            deltaSkipped ? `+${deltaSkipped} skipped` : ""
                        }`;
                            lastFrameRendered = decoder.frameRendered;
                            lastFrameSkipped = decoder.frameSkipped;
                        }),
                        1000,
                    );
                });

                let lastKeyframe = 0n;
                const handler = new InspectStream<ScrcpyMediaStreamPacket>(
                    (packet) => {
                        RECORD_STATE.recorder.addVideoPacket(packet);

                        if (packet.type === "configuration") {
                            let croppedWidth: number;
                            let croppedHeight: number;
                            switch (metadata.codec) {
                                case ScrcpyVideoCodecId.H264:
                                    ({ croppedWidth, croppedHeight } =
                                        h264ParseConfiguration(packet.data));
                                    break;
                                case ScrcpyVideoCodecId.H265:
                                    ({ croppedWidth, croppedHeight } =
                                        h265ParseConfiguration(packet.data));
                                    break;
                                default:
                                    throw new Error("Codec not supported");
                            }

                            runInAction(() => {
                                this.log.push(
                                    `[client] Video size changed: ${croppedWidth}x${croppedHeight}`,
                                );
                                this.width = croppedWidth;
                                this.height = croppedHeight;
                            });
                        } else if (
                            packet.keyframe &&
                            packet.pts !== undefined
                        ) {
                            if (lastKeyframe) {
                                const interval =
                                    (Number(packet.pts - lastKeyframe) / 1000) |
                                    0;
                                runInAction(() => {
                                    this.log.push(
                                        `[client] Keyframe interval: ${interval}ms`,
                                    );
                                });
                            }
                            lastKeyframe = packet.pts!;
                        }
                    },
                );

                stream.pipeThrough(handler).pipeTo(decoder.writable);
            });

            client.audioStream?.then(async (metadata) => {
                switch (metadata.type) {
                    case "disabled":
                        runInAction(() =>
                            this.log.push(
                                `[client] Demuxer audio: stream explicitly disabled by the device`,
                            ),
                        );
                        return;
                    case "errored":
                        runInAction(() =>
                            this.log.push(
                                `[client] Demuxer audio: stream configuration error on the device`,
                            ),
                        );
                        return;
                    case "success":
                        // Code is after this `switch`
                        break;
                    default:
                        throw new Error(
                            `Unexpected audio metadata type ${
                                metadata["type"] as unknown as string
                            }`,
                        );
                }

                const [recordStream, playbackStream] = metadata.stream.tee();
                switch (metadata.codec) {
                    case ScrcpyAudioCodec.RAW: {
                        const audioPlayer = new Int16PcmPlayer(48000);
                        this.audioPlayer = audioPlayer;

                        playbackStream.pipeTo(
                            new WritableStream({
                                write: (chunk) => {
                                    audioPlayer.feed(
                                        new Int16Array(
                                            chunk.data.buffer,
                                            chunk.data.byteOffset,
                                            chunk.data.byteLength /
                                                Int16Array.BYTES_PER_ELEMENT,
                                        ),
                                    );
                                },
                            }),
                        );

                        await this.audioPlayer.start();
                        break;
                    }
                    case ScrcpyAudioCodec.OPUS: {
                        const audioPlayer = new Float32PcmPlayer(48000);
                        this.audioPlayer = audioPlayer;

                        playbackStream
                            .pipeThrough(
                                new OpusDecodeStream({
                                    codec: metadata.codec.webCodecId,
                                    numberOfChannels: 2,
                                    sampleRate: 48000,
                                }),
                            )
                            .pipeTo(
                                new WritableStream({
                                    write: (chunk) => {
                                        audioPlayer.feed(chunk);
                                    },
                                }),
                            );
                        await audioPlayer.start();
                        break;
                    }
                    case ScrcpyAudioCodec.AAC: {
                        const audioPlayer = new Float32PlanerPcmPlayer(48000);
                        this.audioPlayer = audioPlayer;

                        playbackStream
                            .pipeThrough(
                                new AacDecodeStream({
                                    codec: metadata.codec.webCodecId,
                                    numberOfChannels: 2,
                                    sampleRate: 48000,
                                }),
                            )
                            .pipeTo(
                                new WritableStream({
                                    write: (chunk) => {
                                        audioPlayer.feed(chunk);
                                    },
                                }),
                            );
                        await audioPlayer.start();
                        break;
                    }
                    default:
                        throw new Error(
                            `Unsupported audio codec ${metadata.codec.optionValue}`,
                        );
                }

                runInAction(() => {
                    RECORD_STATE.recorder.audioCodec = metadata.codec;
                });

                recordStream.pipeTo(
                    new WritableStream({
                        write: (packet) => {
                            if (packet.type === "data") {
                                RECORD_STATE.recorder.addAudioPacket(packet);
                            }
                        },
                    }),
                );
            });

            client.exit.then(this.dispose);

            client.deviceMessageStream!.pipeTo(
                new WritableStream({
                    write(message) {
                        switch (message.type) {
                            case ScrcpyDeviceMessageType.Clipboard:
                                globalThis.navigator.clipboard.writeText(
                                    message.content,
                                );
                                break;
                        }
                    },
                }),
            );

            if (SETTING_STATE.clientSettings.turnScreenOff) {
                await client.controlMessageWriter!.setScreenPowerMode(
                    AndroidScreenPowerMode.Off,
                );
            }

            runInAction(() => {
                this.client = client;
                this.hoverHelper = new ScrcpyHoverHelper();
                this.running = true;
            });

            const device = GLOBAL_STATE.device!;
            if (device instanceof AdbDaemonWebUsbDevice) {
                this.keyboard = await AoaKeyboardInjector.register(device.raw);
            } else {
                this.keyboard = new ScrcpyKeyboardInjector(client);
            }
        } catch (e: any) {
            GLOBAL_STATE.showErrorDialog(e);
        } finally {
            runInAction(() => {
                this.connecting = false;
            });
        }
    };

    async stop() {
        // Request to close client first
        sendGAEvent("event", "click_stop", {
            serial: GLOBAL_STATE.device?.serial,
            name: GLOBAL_STATE.device?.name,
        });
        await this.client?.close();
        this.dispose();
        this.running = false;
    }

    dispose() {
        // Otherwise some packets may still arrive at decoder
        this.decoder?.dispose();
        this.decoder = undefined;

        if (RECORD_STATE.recording) {
            RECORD_STATE.recorder.stop();
            RECORD_STATE.recording = false;
        }

        this.keyboard?.dispose();
        this.keyboard = undefined;

        this.audioPlayer?.stop();
        this.audioPlayer = undefined;

        this.fps = "0";
        clearTimeout(this.fpsCounterIntervalId);

        if (this.isFullScreen) {
            document.exitFullscreen();
            this.isFullScreen = false;
        }

        this.client = undefined;

        // Only stop running if we also aren't running wirelessly
        if (!this.wirelessStreamClient?.joinedStream) {
            this.running = false;
        }
    }

    setFullScreenContainer(element: HTMLDivElement | null) {
        this.fullScreenContainer = element;
    }

    setWiredStreamContainer(element: HTMLDivElement | null) {
        this.wiredStreamContainer = element;
    }

    setWirelessStreamContainer(element: HTMLDivElement | null) {
        this.wirelessStreamContainer = element;
    }

    clientPositionToDevicePosition(clientX: number, clientY: number) {
        const viewRect = this.wiredStreamContainer!.getBoundingClientRect();
        let pointerViewX = clamp((clientX - viewRect.x) / viewRect.width, 0, 1);
        let pointerViewY = clamp(
            (clientY - viewRect.y) / viewRect.height,
            0,
            1,
        );

        if (this.rotation & 1) {
            [pointerViewX, pointerViewY] = [pointerViewY, pointerViewX];
        }
        switch (this.rotation) {
            case 1:
                pointerViewY = 1 - pointerViewY;
                break;
            case 2:
                pointerViewX = 1 - pointerViewX;
                pointerViewY = 1 - pointerViewY;
                break;
            case 3:
                pointerViewX = 1 - pointerViewX;
                break;
        }

        return {
            x: pointerViewX * this.width,
            y: pointerViewY * this.height,
        };
    }
}

export const STATE = new ScrcpyPageState();
