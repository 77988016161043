import { makeStyles, shorthands } from "@griffel/react";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useStreamingMode } from "../../hooks";
import { STATE } from "./state";

const useClasses = makeStyles({
    stackedStreamContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
    },
    streamContainerContain: {
        height: "100%",
        width: "100%",
        position: "absolute",
        ...shorthands.overflow("hidden"),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > canvas": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
            objectPosition: "center",
        },
    },
    streamContainerCover: {
        height: "100%",
        width: "100%",
        position: "absolute",
        ...shorthands.overflow("hidden"),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > canvas": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
        },
    },
});

export const VideoContainer = observer(() => {
    const classes = useClasses();
    const mode = useStreamingMode();

    const [wiredStreamContainer, setWiredStreamContainer] =
        useState<HTMLDivElement | null>(null);
    const [wirelessStreamContainer, setWirelessStreamContainer] =
        useState<HTMLDivElement | null>(null);

    useEffect(() => {
        STATE.setWiredStreamContainer(wiredStreamContainer);
    }, [wiredStreamContainer]);

    useEffect(() => {
        STATE.setWirelessStreamContainer(wirelessStreamContainer);
    }, [wirelessStreamContainer]);

    const divRef = useRef<HTMLDivElement>(null);
    const [sixteenByNineDimensions, set16x9Dimensions] = useState({
        width: 0,
        height: 0,
    });

    const handleResize = () => {
        if (!divRef.current) return;
        let newDimensions = { width: 0, height: 0 };
        if (divRef.current.offsetWidth / 16 > divRef.current.offsetHeight / 9) {
            newDimensions = {
                width: divRef.current.offsetHeight * (16 / 9),
                height: divRef.current.offsetHeight,
            };
        } else {
            newDimensions = {
                width: divRef.current.offsetWidth,
                height: divRef.current.offsetWidth * (9 / 16),
            };
        }
        set16x9Dimensions(newDimensions);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener("resize", handleResize);

        // Call handleResize immediately to update component state with initial size
        handleResize();

        setTimeout(handleResize, 100); // Hack to fix the initial size after DOM is loaded

        // Return a cleanup function that removes the event listener
        // when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty array ensures this effect runs only once on mount and cleanup on unmount

    const shouldShowWiredStream = !!STATE.client;
    const shouldShowWirelessStream =
        (STATE.wirelessStreamClient?.joinedStream && !shouldShowWiredStream) ||
        mode === "WIRELESS";

    const streamContainerClass =
        STATE.aspectRatio === "1:1"
            ? classes.streamContainerContain
            : classes.streamContainerCover;

    const aspectRatioWidthAndHeight =
        STATE.aspectRatio === "16:9"
            ? sixteenByNineDimensions
            : { width: "100%", height: "100%" };

    return (
        <div ref={divRef} className={classes.stackedStreamContainer}>
            <div
                className={classes.stackedStreamContainer}
                style={aspectRatioWidthAndHeight}
            >
                <div
                    ref={setWirelessStreamContainer}
                    className={streamContainerClass}
                    style={{
                        display: shouldShowWirelessStream ? "inherit" : "none",
                    }}
                />
                <div
                    ref={setWiredStreamContainer}
                    className={streamContainerClass}
                    style={{
                        display: shouldShowWiredStream ? "inherit" : "none",
                    }}
                />
            </div>
        </div>
    );
});
