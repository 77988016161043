// https://w3c.github.io/uievents-code/

export default class UIEventsCode {
   // 3.1.1.1. Writing System Keys
   public static readonly Backquote: string = 'Backquote'
   public static readonly Backslash: string = 'Backslash'
   public static readonly BracketLeft: string = 'BracketLeft'
   public static readonly BracketRight: string = 'BracketRight'
   public static readonly Comma: string = 'Comma'
   public static readonly Digit0: string = 'Digit0'
   public static readonly Digit1: string = 'Digit1'
   public static readonly Digit2: string = 'Digit2'
   public static readonly Digit3: string = 'Digit3'
   public static readonly Digit4: string = 'Digit4'
   public static readonly Digit5: string = 'Digit5'
   public static readonly Digit6: string = 'Digit6'
   public static readonly Digit7: string = 'Digit7'
   public static readonly Digit8: string = 'Digit8'
   public static readonly Digit9: string = 'Digit9'
   public static readonly Equal: string = 'Equal'
   public static readonly IntlBackslash: string = 'IntlBackslash'
   public static readonly IntlRo: string = 'IntlRo'
   public static readonly IntlYen: string = 'IntlYen'
   public static readonly KeyA: string = 'KeyA'
   public static readonly KeyB: string = 'KeyB'
   public static readonly KeyC: string = 'KeyC'
   public static readonly KeyD: string = 'KeyD'
   public static readonly KeyE: string = 'KeyE'
   public static readonly KeyF: string = 'KeyF'
   public static readonly KeyG: string = 'KeyG'
   public static readonly KeyH: string = 'KeyH'
   public static readonly KeyI: string = 'KeyI'
   public static readonly KeyJ: string = 'KeyJ'
   public static readonly KeyK: string = 'KeyK'
   public static readonly KeyL: string = 'KeyL'
   public static readonly KeyM: string = 'KeyM'
   public static readonly KeyN: string = 'KeyN'
   public static readonly KeyO: string = 'KeyO'
   public static readonly KeyP: string = 'KeyP'
   public static readonly KeyQ: string = 'KeyQ'
   public static readonly KeyR: string = 'KeyR'
   public static readonly KeyS: string = 'KeyS'
   public static readonly KeyT: string = 'KeyT'
   public static readonly KeyU: string = 'KeyU'
   public static readonly KeyV: string = 'KeyV'
   public static readonly KeyW: string = 'KeyW'
   public static readonly KeyX: string = 'KeyX'
   public static readonly KeyY: string = 'KeyY'
   public static readonly KeyZ: string = 'KeyZ'
   public static readonly Minus: string = 'Minus'
   public static readonly Period: string = 'Period'
   public static readonly Quote: string = 'Quote'
   public static readonly Semicolon: string = 'Semicolon'
   public static readonly Slash: string = 'Slash'

   // 3.1.1.2. Functional Keys
   public static readonly AltLeft: string = 'AltLeft'
   public static readonly AltRight: string = 'AltRight'
   public static readonly Backspace: string = 'Backspace'
   public static readonly CapsLock: string = 'CapsLock'
   public static readonly ContextMenu: string = 'ContextMenu'
   public static readonly ControlLeft: string = 'ControlLeft'
   public static readonly ControlRight: string = 'ControlRight'
   public static readonly Enter: string = 'Enter'
   public static readonly MetaLeft: string = 'MetaLeft'
   public static readonly MetaRight: string = 'MetaRight'
   public static readonly ShiftLeft: string = 'ShiftLeft'
   public static readonly ShiftRight: string = 'ShiftRight'
   public static readonly Space: string = 'Space'
   public static readonly Tab: string = 'Tab'
   public static readonly Convert: string = 'Convert'
   public static readonly KanaMode: string = 'KanaMode'
   public static readonly Lang1: string = 'Lang1'
   public static readonly Lang2: string = 'Lang2'
   public static readonly Lang3: string = 'Lang3'
   public static readonly Lang4: string = 'Lang4'
   public static readonly Lang5: string = 'Lang5'
   public static readonly NonConvert: string = 'NonConvert'

   // 3.1.2. Control Pad Section
   public static readonly Delete: string = 'Delete'
   public static readonly End: string = 'End'
   public static readonly Help: string = 'Help'
   public static readonly Home: string = 'Home'
   public static readonly Insert: string = 'Insert'
   public static readonly PageDown: string = 'PageDown'
   public static readonly PageUp: string = 'PageUp'

   // 3.1.3. Arrow Pad Section
   public static readonly ArrowDown: string = 'ArrowDown'
   public static readonly ArrowLeft: string = 'ArrowLeft'
   public static readonly ArrowRight: string = 'ArrowRight'
   public static readonly ArrowUp: string = 'ArrowUp'

   // 3.1.4. Numpad Section
   public static readonly NumLock: string = 'NumLock'
   public static readonly Numpad0: string = 'Numpad0'
   public static readonly Numpad1: string = 'Numpad1'
   public static readonly Numpad2: string = 'Numpad2'
   public static readonly Numpad3: string = 'Numpad3'
   public static readonly Numpad4: string = 'Numpad4'
   public static readonly Numpad5: string = 'Numpad5'
   public static readonly Numpad6: string = 'Numpad6'
   public static readonly Numpad7: string = 'Numpad7'
   public static readonly Numpad8: string = 'Numpad8'
   public static readonly Numpad9: string = 'Numpad9'
   public static readonly NumpadAdd: string = 'NumpadAdd'
   public static readonly NumpadBackspace: string = 'NumpadBackspace'
   public static readonly NumpadClear: string = 'NumpadClear'
   public static readonly NumpadClearEntry: string = 'NumpadClearEntry'
   public static readonly NumpadComma: string = 'NumpadComma'
   public static readonly NumpadDecimal: string = 'NumpadDecimal'
   public static readonly NumpadDivide: string = 'NumpadDivide'
   public static readonly NumpadEnter: string = 'NumpadEnter'
   public static readonly NumpadEqual: string = 'NumpadEqual'
   public static readonly NumpadHash: string = 'NumpadHash'
   public static readonly NumpadMemoryAdd: string = 'NumpadMemoryAdd'
   public static readonly NumpadMemoryClear: string = 'NumpadMemoryClear'
   public static readonly NumpadMemoryRecall: string = 'NumpadMemoryRecall'
   public static readonly NumpadMemoryStore: string = 'NumpadMemoryStore'
   public static readonly NumpadMemorySubtract: string = 'NumpadMemorySubtract'
   public static readonly NumpadMultiply: string = 'NumpadMultiply'
   public static readonly NumpadParenLeft: string = 'NumpadParenLeft'
   public static readonly NumpadParenRight: string = 'NumpadParenRight'
   public static readonly NumpadStar: string = 'NumpadStar'
   public static readonly NumpadSubtract: string = 'NumpadSubtract'

   // 3.1.5. Function Section
   public static readonly Escape: string = 'Escape'
   public static readonly F1: string = 'F1'
   public static readonly F2: string = 'F2'
   public static readonly F3: string = 'F3'
   public static readonly F4: string = 'F4'
   public static readonly F5: string = 'F5'
   public static readonly F6: string = 'F6'
   public static readonly F7: string = 'F7'
   public static readonly F8: string = 'F8'
   public static readonly F9: string = 'F9'
   public static readonly F10: string = 'F10'
   public static readonly F11: string = 'F11'
   public static readonly F12: string = 'F12'
   public static readonly Fn: string = 'Fn'
   public static readonly FnLock: string = 'FnLock'
   public static readonly PrintScreen: string = 'PrintScreen'
   public static readonly ScrollLock: string = 'ScrollLock'
   public static readonly Pause: string = 'Pause'

   // 3.1.6. Media Keys
   public static readonly BrowserBack: string = 'BrowserBack'
   public static readonly BrowserFavorites: string = 'BrowserFavorites'
   public static readonly BrowserForward: string = 'BrowserForward'
   public static readonly BrowserHome: string = 'BrowserHome'
   public static readonly BrowserRefresh: string = 'BrowserRefresh'
   public static readonly BrowserSearch: string = 'BrowserSearch'
   public static readonly BrowserStop: string = 'BrowserStop'
   public static readonly Eject: string = 'Eject'
   public static readonly LaunchApp1: string = 'LaunchApp1'
   public static readonly LaunchApp2: string = 'LaunchApp2'
   public static readonly LaunchMail: string = 'LaunchMail'
   public static readonly MediaPlayPause: string = 'MediaPlayPause'
   public static readonly MediaSelect: string = 'MediaSelect'
   public static readonly MediaStop: string = 'MediaStop'
   public static readonly MediaTrackNext: string = 'MediaTrackNext'
   public static readonly MediaTrackPrevious: string = 'MediaTrackPrevious'
   public static readonly Power: string = 'Power'
   public static readonly Sleep: string = 'Sleep'
   public static readonly AudioVolumeDown: string = 'AudioVolumeDown'
   public static readonly AudioVolumeMute: string = 'AudioVolumeMute'
   public static readonly AudioVolumeUp: string = 'AudioVolumeUp'
   public static readonly WakeUp: string = 'WakeUp'

   // 3.1.7. Legacy, Non-Standard and Special Keys
   public static readonly Hyper: string = 'Hyper'
   public static readonly Super: string = 'Super'
   public static readonly Turbo: string = 'Turbo'
   public static readonly Abort: string = 'Abort'
   public static readonly Resume: string = 'Resume'
   public static readonly Suspend: string = 'Suspend'
   public static readonly Again: string = 'Again'
   public static readonly Copy: string = 'Copy'
   public static readonly Cut: string = 'Cut'
   public static readonly Find: string = 'Find'
   public static readonly Open: string = 'Open'
   public static readonly Paste: string = 'Paste'
   public static readonly Props: string = 'Props'
   public static readonly Select: string = 'Select'
   public static readonly Undo: string = 'Undo'
   public static readonly Hiragana: string = 'Hiragana'
   public static readonly Katakana: string = 'Katakana'
   public static readonly Unidentified: string = 'Unidentified'
}
