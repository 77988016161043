export default class KeyEvent {
   public static readonly ACTION_DOWN: number = 0
   public static readonly ACTION_UP: number = 1

   public static readonly KEYCODE_0: number = 7
   public static readonly KEYCODE_1: number = 8
   public static readonly KEYCODE_11: number = 227
   public static readonly KEYCODE_12: number = 228
   public static readonly KEYCODE_2: number = 9
   public static readonly KEYCODE_3: number = 10
   public static readonly KEYCODE_3D_MODE: number = 206
   public static readonly KEYCODE_4: number = 11
   public static readonly KEYCODE_5: number = 12
   public static readonly KEYCODE_6: number = 13
   public static readonly KEYCODE_7: number = 14
   public static readonly KEYCODE_8: number = 15
   public static readonly KEYCODE_9: number = 16
   public static readonly KEYCODE_A: number = 29
   public static readonly KEYCODE_ALL_APPS: number = 284
   public static readonly KEYCODE_ALT_LEFT: number = 57
   public static readonly KEYCODE_ALT_RIGHT: number = 58
   public static readonly KEYCODE_APOSTROPHE: number = 75
   public static readonly KEYCODE_APP_SWITCH: number = 187
   public static readonly KEYCODE_ASSIST: number = 219
   public static readonly KEYCODE_AT: number = 77
   public static readonly KEYCODE_AVR_INPUT: number = 182
   public static readonly KEYCODE_AVR_POWER: number = 181
   public static readonly KEYCODE_B: number = 30
   public static readonly KEYCODE_BACK: number = 4
   public static readonly KEYCODE_BACKSLASH: number = 73
   public static readonly KEYCODE_BOOKMARK: number = 174
   public static readonly KEYCODE_BREAK: number = 121
   public static readonly KEYCODE_BRIGHTNESS_DOWN: number = 220
   public static readonly KEYCODE_BRIGHTNESS_UP: number = 221
   public static readonly KEYCODE_BUTTON_1: number = 188
   public static readonly KEYCODE_BUTTON_10: number = 197
   public static readonly KEYCODE_BUTTON_11: number = 198
   public static readonly KEYCODE_BUTTON_12: number = 199
   public static readonly KEYCODE_BUTTON_13: number = 200
   public static readonly KEYCODE_BUTTON_14: number = 201
   public static readonly KEYCODE_BUTTON_15: number = 202
   public static readonly KEYCODE_BUTTON_16: number = 203
   public static readonly KEYCODE_BUTTON_2: number = 189
   public static readonly KEYCODE_BUTTON_3: number = 190
   public static readonly KEYCODE_BUTTON_4: number = 191
   public static readonly KEYCODE_BUTTON_5: number = 192
   public static readonly KEYCODE_BUTTON_6: number = 193
   public static readonly KEYCODE_BUTTON_7: number = 194
   public static readonly KEYCODE_BUTTON_8: number = 195
   public static readonly KEYCODE_BUTTON_9: number = 196
   public static readonly KEYCODE_BUTTON_A: number = 96
   public static readonly KEYCODE_BUTTON_B: number = 97
   public static readonly KEYCODE_BUTTON_C: number = 98
   public static readonly KEYCODE_BUTTON_L1: number = 102
   public static readonly KEYCODE_BUTTON_L2: number = 104
   public static readonly KEYCODE_BUTTON_MODE: number = 110
   public static readonly KEYCODE_BUTTON_R1: number = 103
   public static readonly KEYCODE_BUTTON_R2: number = 105
   public static readonly KEYCODE_BUTTON_SELECT: number = 109
   public static readonly KEYCODE_BUTTON_START: number = 108
   public static readonly KEYCODE_BUTTON_THUMBL: number = 106
   public static readonly KEYCODE_BUTTON_THUMBR: number = 107
   public static readonly KEYCODE_BUTTON_X: number = 99
   public static readonly KEYCODE_BUTTON_Y: number = 100
   public static readonly KEYCODE_BUTTON_Z: number = 101
   public static readonly KEYCODE_C: number = 31
   public static readonly KEYCODE_CALCULATOR: number = 210
   public static readonly KEYCODE_CALENDAR: number = 208
   public static readonly KEYCODE_CALL: number = 5
   public static readonly KEYCODE_CAMERA: number = 27
   public static readonly KEYCODE_CAPS_LOCK: number = 115
   public static readonly KEYCODE_CAPTIONS: number = 175
   public static readonly KEYCODE_CHANNEL_DOWN: number = 167
   public static readonly KEYCODE_CHANNEL_UP: number = 166
   public static readonly KEYCODE_CLEAR: number = 28
   public static readonly KEYCODE_COMMA: number = 55
   public static readonly KEYCODE_CONTACTS: number = 207
   public static readonly KEYCODE_COPY: number = 278
   public static readonly KEYCODE_CTRL_LEFT: number = 113
   public static readonly KEYCODE_CTRL_RIGHT: number = 114
   public static readonly KEYCODE_CUT: number = 277
   public static readonly KEYCODE_D: number = 32
   public static readonly KEYCODE_DEL: number = 67
   public static readonly KEYCODE_DPAD_CENTER: number = 23
   public static readonly KEYCODE_DPAD_DOWN: number = 20
   public static readonly KEYCODE_DPAD_DOWN_LEFT: number = 269
   public static readonly KEYCODE_DPAD_DOWN_RIGHT: number = 271
   public static readonly KEYCODE_DPAD_LEFT: number = 21
   public static readonly KEYCODE_DPAD_RIGHT: number = 22
   public static readonly KEYCODE_DPAD_UP: number = 19
   public static readonly KEYCODE_DPAD_UP_LEFT: number = 268
   public static readonly KEYCODE_DPAD_UP_RIGHT: number = 270
   public static readonly KEYCODE_DVR: number = 173
   public static readonly KEYCODE_E: number = 33
   public static readonly KEYCODE_EISU: number = 212
   public static readonly KEYCODE_ENDCALL: number = 6
   public static readonly KEYCODE_ENTER: number = 66
   public static readonly KEYCODE_ENVELOPE: number = 65
   public static readonly KEYCODE_EQUALS: number = 70
   public static readonly KEYCODE_ESCAPE: number = 111
   public static readonly KEYCODE_EXPLORER: number = 64
   public static readonly KEYCODE_F: number = 34
   public static readonly KEYCODE_F1: number = 131
   public static readonly KEYCODE_F10: number = 140
   public static readonly KEYCODE_F11: number = 141
   public static readonly KEYCODE_F12: number = 142
   public static readonly KEYCODE_F2: number = 132
   public static readonly KEYCODE_F3: number = 133
   public static readonly KEYCODE_F4: number = 134
   public static readonly KEYCODE_F5: number = 135
   public static readonly KEYCODE_F6: number = 136
   public static readonly KEYCODE_F7: number = 137
   public static readonly KEYCODE_F8: number = 138
   public static readonly KEYCODE_F9: number = 139
   public static readonly KEYCODE_FOCUS: number = 80
   public static readonly KEYCODE_FORWARD: number = 125
   public static readonly KEYCODE_FORWARD_DEL: number = 112
   public static readonly KEYCODE_FUNCTION: number = 119
   public static readonly KEYCODE_G: number = 35
   public static readonly KEYCODE_GRAVE: number = 68
   public static readonly KEYCODE_GUIDE: number = 172
   public static readonly KEYCODE_H: number = 36
   public static readonly KEYCODE_HEADSETHOOK: number = 79
   public static readonly KEYCODE_HELP: number = 259
   public static readonly KEYCODE_HENKAN: number = 214
   public static readonly KEYCODE_HOME: number = 3
   public static readonly KEYCODE_I: number = 37
   public static readonly KEYCODE_INFO: number = 165
   public static readonly KEYCODE_INSERT: number = 124
   public static readonly KEYCODE_J: number = 38
   public static readonly KEYCODE_K: number = 39
   public static readonly KEYCODE_KANA: number = 218
   public static readonly KEYCODE_KATAKANA_HIRAGANA: number = 215
   public static readonly KEYCODE_L: number = 40
   public static readonly KEYCODE_LANGUAGE_SWITCH: number = 204
   public static readonly KEYCODE_LAST_CHANNEL: number = 229
   public static readonly KEYCODE_LEFT_BRACKET: number = 71
   public static readonly KEYCODE_M: number = 41
   public static readonly KEYCODE_MANNER_MODE: number = 205
   public static readonly KEYCODE_MEDIA_AUDIO_TRACK: number = 222
   public static readonly KEYCODE_MEDIA_CLOSE: number = 128
   public static readonly KEYCODE_MEDIA_EJECT: number = 129
   public static readonly KEYCODE_MEDIA_FAST_FORWARD: number = 90
   public static readonly KEYCODE_MEDIA_NEXT: number = 87
   public static readonly KEYCODE_MEDIA_PAUSE: number = 127
   public static readonly KEYCODE_MEDIA_PLAY: number = 126
   public static readonly KEYCODE_MEDIA_PLAY_PAUSE: number = 85
   public static readonly KEYCODE_MEDIA_PREVIOUS: number = 88
   public static readonly KEYCODE_MEDIA_RECORD: number = 130
   public static readonly KEYCODE_MEDIA_REWIND: number = 89
   public static readonly KEYCODE_MEDIA_SKIP_BACKWARD: number = 273
   public static readonly KEYCODE_MEDIA_SKIP_FORWARD: number = 272
   public static readonly KEYCODE_MEDIA_STEP_BACKWARD: number = 275
   public static readonly KEYCODE_MEDIA_STEP_FORWARD: number = 274
   public static readonly KEYCODE_MEDIA_STOP: number = 86
   public static readonly KEYCODE_MEDIA_TOP_MENU: number = 226
   public static readonly KEYCODE_MENU: number = 82
   public static readonly KEYCODE_META_LEFT: number = 117
   public static readonly KEYCODE_META_RIGHT: number = 118
   public static readonly KEYCODE_MINUS: number = 69
   public static readonly KEYCODE_MOVE_END: number = 123
   public static readonly KEYCODE_MOVE_HOME: number = 122
   public static readonly KEYCODE_MUHENKAN: number = 213
   public static readonly KEYCODE_MUSIC: number = 209
   public static readonly KEYCODE_MUTE: number = 91
   public static readonly KEYCODE_N: number = 42
   public static readonly KEYCODE_NAVIGATE_IN: number = 262
   public static readonly KEYCODE_NAVIGATE_NEXT: number = 261
   public static readonly KEYCODE_NAVIGATE_OUT: number = 263
   public static readonly KEYCODE_NAVIGATE_PREVIOUS: number = 260
   public static readonly KEYCODE_NOTIFICATION: number = 83
   public static readonly KEYCODE_NUM: number = 78
   public static readonly KEYCODE_NUMPAD_0: number = 144
   public static readonly KEYCODE_NUMPAD_1: number = 145
   public static readonly KEYCODE_NUMPAD_2: number = 146
   public static readonly KEYCODE_NUMPAD_3: number = 147
   public static readonly KEYCODE_NUMPAD_4: number = 148
   public static readonly KEYCODE_NUMPAD_5: number = 149
   public static readonly KEYCODE_NUMPAD_6: number = 150
   public static readonly KEYCODE_NUMPAD_7: number = 151
   public static readonly KEYCODE_NUMPAD_8: number = 152
   public static readonly KEYCODE_NUMPAD_9: number = 153
   public static readonly KEYCODE_NUMPAD_ADD: number = 157
   public static readonly KEYCODE_NUMPAD_COMMA: number = 159
   public static readonly KEYCODE_NUMPAD_DIVIDE: number = 154
   public static readonly KEYCODE_NUMPAD_DOT: number = 158
   public static readonly KEYCODE_NUMPAD_ENTER: number = 160
   public static readonly KEYCODE_NUMPAD_EQUALS: number = 161
   public static readonly KEYCODE_NUMPAD_LEFT_PAREN: number = 162
   public static readonly KEYCODE_NUMPAD_MULTIPLY: number = 155
   public static readonly KEYCODE_NUMPAD_RIGHT_PAREN: number = 163
   public static readonly KEYCODE_NUMPAD_SUBTRACT: number = 156
   public static readonly KEYCODE_NUM_LOCK: number = 143
   public static readonly KEYCODE_O: number = 43
   public static readonly KEYCODE_P: number = 44
   public static readonly KEYCODE_PAGE_DOWN: number = 93
   public static readonly KEYCODE_PAGE_UP: number = 92
   public static readonly KEYCODE_PAIRING: number = 225
   public static readonly KEYCODE_PASTE: number = 279
   public static readonly KEYCODE_PERIOD: number = 56
   public static readonly KEYCODE_PICTSYMBOLS: number = 94
   public static readonly KEYCODE_PLUS: number = 81
   public static readonly KEYCODE_POUND: number = 18
   public static readonly KEYCODE_POWER: number = 26
   public static readonly KEYCODE_PROFILE_SWITCH: number = 288
   public static readonly KEYCODE_PROG_BLUE: number = 186
   public static readonly KEYCODE_PROG_GREEN: number = 184
   public static readonly KEYCODE_PROG_RED: number = 183
   public static readonly KEYCODE_PROG_YELLOW: number = 185
   public static readonly KEYCODE_Q: number = 45
   public static readonly KEYCODE_R: number = 46
   public static readonly KEYCODE_REFRESH: number = 285
   public static readonly KEYCODE_RIGHT_BRACKET: number = 72
   public static readonly KEYCODE_RO: number = 217
   public static readonly KEYCODE_S: number = 47
   public static readonly KEYCODE_SCROLL_LOCK: number = 116
   public static readonly KEYCODE_SEARCH: number = 84
   public static readonly KEYCODE_SEMICOLON: number = 74
   public static readonly KEYCODE_SETTINGS: number = 176
   public static readonly KEYCODE_SHIFT_LEFT: number = 59
   public static readonly KEYCODE_SHIFT_RIGHT: number = 60
   public static readonly KEYCODE_SLASH: number = 76
   public static readonly KEYCODE_SLEEP: number = 223
   public static readonly KEYCODE_SOFT_LEFT: number = 1
   public static readonly KEYCODE_SOFT_RIGHT: number = 2
   public static readonly KEYCODE_SOFT_SLEEP: number = 276
   public static readonly KEYCODE_SPACE: number = 62
   public static readonly KEYCODE_STAR: number = 17
   public static readonly KEYCODE_STB_INPUT: number = 180
   public static readonly KEYCODE_STB_POWER: number = 179
   public static readonly KEYCODE_STEM_1: number = 265
   public static readonly KEYCODE_STEM_2: number = 266
   public static readonly KEYCODE_STEM_3: number = 267
   public static readonly KEYCODE_STEM_PRIMARY: number = 264
   public static readonly KEYCODE_SWITCH_CHARSET: number = 95
   public static readonly KEYCODE_SYM: number = 63
   public static readonly KEYCODE_SYSRQ: number = 120
   public static readonly KEYCODE_SYSTEM_NAVIGATION_DOWN: number = 281
   public static readonly KEYCODE_SYSTEM_NAVIGATION_LEFT: number = 282
   public static readonly KEYCODE_SYSTEM_NAVIGATION_RIGHT: number = 283
   public static readonly KEYCODE_SYSTEM_NAVIGATION_UP: number = 280
   public static readonly KEYCODE_T: number = 48
   public static readonly KEYCODE_TAB: number = 61
   public static readonly KEYCODE_THUMBS_DOWN: number = 287
   public static readonly KEYCODE_THUMBS_UP: number = 286
   public static readonly KEYCODE_TV: number = 170
   public static readonly KEYCODE_TV_ANTENNA_CABLE: number = 242
   public static readonly KEYCODE_TV_AUDIO_DESCRIPTION: number = 252
   public static readonly KEYCODE_TV_AUDIO_DESCRIPTION_MIX_DOWN: number = 254
   public static readonly KEYCODE_TV_AUDIO_DESCRIPTION_MIX_UP: number = 253
   public static readonly KEYCODE_TV_CONTENTS_MENU: number = 256
   public static readonly KEYCODE_TV_DATA_SERVICE: number = 230
   public static readonly KEYCODE_TV_INPUT: number = 178
   public static readonly KEYCODE_TV_INPUT_COMPONENT_1: number = 249
   public static readonly KEYCODE_TV_INPUT_COMPONENT_2: number = 250
   public static readonly KEYCODE_TV_INPUT_COMPOSITE_1: number = 247
   public static readonly KEYCODE_TV_INPUT_COMPOSITE_2: number = 248
   public static readonly KEYCODE_TV_INPUT_HDMI_1: number = 243
   public static readonly KEYCODE_TV_INPUT_HDMI_2: number = 244
   public static readonly KEYCODE_TV_INPUT_HDMI_3: number = 245
   public static readonly KEYCODE_TV_INPUT_HDMI_4: number = 246
   public static readonly KEYCODE_TV_INPUT_VGA_1: number = 251
   public static readonly KEYCODE_TV_MEDIA_CONTEXT_MENU: number = 257
   public static readonly KEYCODE_TV_NETWORK: number = 241
   public static readonly KEYCODE_TV_NUMBER_ENTRY: number = 234
   public static readonly KEYCODE_TV_POWER: number = 177
   public static readonly KEYCODE_TV_RADIO_SERVICE: number = 232
   public static readonly KEYCODE_TV_SATELLITE: number = 237
   public static readonly KEYCODE_TV_SATELLITE_BS: number = 238
   public static readonly KEYCODE_TV_SATELLITE_CS: number = 239
   public static readonly KEYCODE_TV_SATELLITE_SERVICE: number = 240
   public static readonly KEYCODE_TV_TELETEXT: number = 233
   public static readonly KEYCODE_TV_TERRESTRIAL_ANALOG: number = 235
   public static readonly KEYCODE_TV_TERRESTRIAL_DIGITAL: number = 236
   public static readonly KEYCODE_TV_TIMER_PROGRAMMING: number = 258
   public static readonly KEYCODE_TV_ZOOM_MODE: number = 255
   public static readonly KEYCODE_U: number = 49
   public static readonly KEYCODE_UNKNOWN: number = 0
   public static readonly KEYCODE_V: number = 50
   public static readonly KEYCODE_VOICE_ASSIST: number = 231
   public static readonly KEYCODE_VOLUME_DOWN: number = 25
   public static readonly KEYCODE_VOLUME_MUTE: number = 164
   public static readonly KEYCODE_VOLUME_UP: number = 24
   public static readonly KEYCODE_W: number = 51
   public static readonly KEYCODE_WAKEUP: number = 224
   public static readonly KEYCODE_WINDOW: number = 171
   public static readonly KEYCODE_X: number = 52
   public static readonly KEYCODE_Y: number = 53
   public static readonly KEYCODE_YEN: number = 216
   public static readonly KEYCODE_Z: number = 54
   public static readonly KEYCODE_ZENKAKU_HANKAKU: number = 211
   public static readonly KEYCODE_ZOOM_IN: number = 168
   public static readonly KEYCODE_ZOOM_OUT: number = 169

   public static readonly META_ALT_LEFT_ON: number = 16
   public static readonly META_ALT_MASK: number = 50
   public static readonly META_ALT_ON: number = 2
   public static readonly META_ALT_RIGHT_ON: number = 32
   public static readonly META_CAPS_LOCK_ON: number = 1048576
   public static readonly META_CTRL_LEFT_ON: number = 8192
   public static readonly META_CTRL_MASK: number = 28672
   public static readonly META_CTRL_ON: number = 4096
   public static readonly META_CTRL_RIGHT_ON: number = 16384
   public static readonly META_FUNCTION_ON: number = 8
   public static readonly META_META_LEFT_ON: number = 131072
   public static readonly META_META_MASK: number = 458752
   public static readonly META_META_ON: number = 65536
   public static readonly META_META_RIGHT_ON: number = 262144
   public static readonly META_NUM_LOCK_ON: number = 2097152
   public static readonly META_SCROLL_LOCK_ON: number = 4194304
   public static readonly META_SHIFT_LEFT_ON: number = 64
   public static readonly META_SHIFT_MASK: number = 193
   public static readonly META_SHIFT_ON: number = 1
   public static readonly META_SHIFT_RIGHT_ON: number = 128
   public static readonly META_SYM_ON: number = 4
}
